<template>
    <div>
        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="!isLoading">
            <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
                <div class="modal rounded-md py-10 px-6 md:px-10">
                    <form @submit.prevent="submitForm" class="modal-content">
                        <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                            <label for="username">
                                クリエーター名:
                            </label>
                            <input v-model="username" type="text" name="username" id="username"
                                class="border border-gray-200 p-2 outline-none rounded-md bg-transparent"
                            />
                        </div>

                        <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                            <label for="email">
                                メールアドレス:
                            </label>
                            <input v-model="email" type="email" name="email" id="email"
                                class="border border-gray-200 p-2 outline-none rounded-md bg-transparent"
                            />
                        </div>

                        <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                            <label for="password">
                                パスワード:
                            </label>
                            <input v-model="password" type="password" name="password" id="password" autocomplete="new-password"
                                class="border border-gray-200 p-2 outline-none rounded-md bg-transparent"
                            />
                        </div>

                        <div class="flex flex-col gap-2 mb-6">
                            <label for="password2">
                                確認用パスワード:
                            </label>
                            <input v-model="password2" type="password" id="password2"
                                class="border border-gray-200 p-2 outline-none rounded-md bg-transparent"
                            />
                        </div>

                        <div class="text-red-500 text-sm" v-if="errors.length">
                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                        </div>

                        <div class="flex justify-center gap-8 mt-4 sm:mt-6">
                            <span
                                @click.stop="closeModal"
                                class="w-40 rounded-full px-4 py-[9px] text-[14px] sm:text-[15px] bg-[#353946] border border-gray-200 hover:bg-[#2e3240] hover:text-gray-300 font-bold transition-all flex items-center justify-center cursor-pointer"
                            >
                                キャンセル
                            </span>
                            <button 
                                type="submit"
                                :disabled="isSubmitting"
                                class="py-3 tracking-wider w-40 font-bold text-white cursor-pointer rounded-full bg-[#6046E3] hover:bg-[#4c3cb1] hover:text-gray-300 transition-all"
                            >
                                {{ isSubmitting ? '登録中...' : '登録する' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from "@/components/Loading"
import Dropdown from "@/components/Common/Dropdown"

export default {
    props: {
        ['isOpen']: Boolean,
    },
    components: {
        Loading,
        Dropdown
    },
    data() {
        return {
            username: '',
            email: '',
            password: '',
            password2: '',
            errors: [],
        };
    },
    methods: {
        async submitForm() {
            this.isSubmitting = true;
            this.errors = []

            if (this.username === '') {
                this.errors.push('クリエーター名を記入して下さい。')
            } else if (this.email === '') {
                this.errors.push('メールアドレスを記入して下さい。')
            } else if (this.password === '') {
                this.errors.push('パスワードを記入して下さい。')
            } else if (this.password2 === '') {
                this.errors.push('確認用パスワードを記入して下さい。')
            } 
            else if (this.password !== this.password2) {
                this.errors.push('パスワードが一致しません。')
            }

            if (!this.errors.length) {
                const formData = {
                    username: this.username,
                    email: this.email,
                    password: this.password
                }
                try {
                    await axios.post("/api/v1/register/", formData);

                    this.$store.commit('setFlashMessage', { message: 'クリエーターを登録しました', type: 'success' });
                    this.$emit('update-users');
                    this.closeModal();

                    setTimeout(() => {
                        this.$store.commit('clearFlashMessage');
                    }, 2000);
                } catch (error) {
                    if (error.response && error.response.data) {
                        // APIからのエラーメッセージを処理
                        const errors = error.response.data;
                        for (const key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                const errorMessages = errors[key];
                                if (Array.isArray(errorMessages)) {
                                    errorMessages.forEach(message => this.errors.push(message));
                                } else {
                                    this.errors.push(errorMessages);
                                }
                            }
                        }
                    } else {
                        this.$store.commit('setFlashMessage', { message: 'クリエーターの登録に失敗しました', status: 'error' });
                        setTimeout(() => {
                            this.$store.commit('clearFlashMessage');
                        }, 2000);
                    }
                }
            }
            this.isSubmitting = false;
        },
        closeModal() {
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 650px;
    width: 95%;
    max-height: 95%;
    background-color: #23202C;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #353945;
}
.modal-content {
    display: flex;
    flex-direction: column;
}
</style>