<template>
    <div>
        <table class="w-full">
            <thead>
                <tr class="bg-[#353946]">
                    <th class="w-10">No.</th>
                    <th>クリエーター名</th>
                    <th>肩書き</th>
                    <th>カテゴリー</th>
                    <th>メールアドレス</th>
                    <th>投稿数</th>
                    <th>相談数</th>
                    <th>登録日</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in users" :key="user.id" class="border-b border-gray-200">
                    <td>{{ users.length - index }}</td>
                    <td class="flex items-center gap-3">
                        <div 
                            v-if="user.get_avatar"
                            class="w-11 h-11 rounded-full"
                        >
                            <img :src="user.get_avatar" class="rounded-full w-full h-full object-cover" />
                        </div>
                        <div 
                            v-else
                            class="w-11 h-11 rounded-full"
                        >
                            <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                        </div>
                        <p class="max-w-[200px] overflow-x-auto">
                            {{ user.username }}
                        </p>
                    </td>
                    <td>
                        <p class="max-w-[200px] overflow-x-auto">
                            {{ user.job }}
                        </p>
                    </td>
                    <td>{{ user.category }}</td>
                    <td>
                        <p 
                            class="max-w-[250px] overflow-x-auto cursor-pointer hover:text-blue-500 transition-all"
                            @click="copyToClipboard(user.email)"
                        >
                            {{ user.email }}
                        </p>
                    </td>
                    <td>
                        <span class="px-4">{{ user.blogs.length }}</span>
                    </td>
                    <td>
                        <span class="px-4">{{ user.offers.length }}</span>
                    </td>
                    <td>{{ user.date_joined }}</td>
                    <td>
                        <div class="flex gap-[10px]">
                            <button 
                                class="text-sm rounded-full w-8 h-8 transition-all bg-[#6046e3] hover:bg-[#4c3cb1] hover:text-gray-300"
                                @click="sendMessage(user)"
                            >
                                <i class="fas fa-envelope"></i>
                            </button>
                            <button 
                                class="text-sm bg-green-600 hover:bg-green-700 rounded-full w-8 h-8 transition-all hover:text-gray-200"
                                @click="editUser(user)"
                            >
                                <i class="fas fa-edit"></i>
                            </button>
                            <div v-if="user.is_admin === false">
                                <button 
                                    class="text-sm bg-red-600 hover:bg-red-700 rounded-full w-8 h-8 transition-all hover:text-gray-200"
                                    @click="deleteUser(user.id)"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "creators-table",
    props: {
        users: {
            type: Array,
            required: true
        },
    },
    methods: {
        editUser(user) {
            this.$emit('edit-user', user);
        },
        sendMessage(user) {
            this.$emit('send-message', user);
        },
        deleteUser(userId) {
            this.$emit('delete-user', userId);
        },
    }
}
</script>
