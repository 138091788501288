<template>
    <div class="mt-14">
        <hr class="mb-12 border-t border-[#E5E7EB]" />
        <h3 class="text-xl sm:text-2xl font-bold mb-6">参加クリエーター</h3>

        <div class="grid sm:grid-cols-2 gap-x-6 gap-y-5">
            <div v-for="participant in blog.participants" v-bind:key="participant.id">
                <router-link v-bind:to="participant.get_absolute_url" class="border-white flex items-center gap-3 hover:opacity-80 transition-all">
                    <div v-if="participant.get_avatar" class="w-14 h-14">
                        <img :src="participant.get_avatar" class="rounded-full w-full h-full object-cover" />
                    </div>
                    <div v-else class="w-14 h-14">
                        <img src="../../assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                    </div>

                    <div class="flex flex-col items-start">
                        <p v-if="participant.job" class="text-negative text-xs font-bold">
                            {{ participant.job }}
                        </p>
                        <p class="font-bold text-[15px]">
                            {{ participant.username }}
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        blog: Object
    },
    data() {
        return {
            date: this.blog.created,
        }
    }
}
</script>