<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
        <div class="modal rounded-md px-6 md:px-28">
            <form @submit.prevent="submitForm" class="modal-content">
                <h1 class="text-lg sm:text-xl font-bold text-center mb-10">
                    クリエイターに相談する
                </h1>

                <div>
                    <h2 class="font-bold mb-4">このクリエーターに相談する</h2>

                    <div class="flex gap-4 mb-8">
                        <div v-if="blog.host.get_avatar" class="w-16 h-16">
                            <img :src="blog.host.get_avatar" class="rounded-full w-full h-full object-cover" />
                        </div>
                        <div v-else class="w-16 h-16">
                            <img src="../../assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                        </div>

                        <div class="flex flex-col items-start justify-center gap-1">
                            <span class="text-negative text-xs font-bold">
                                {{ blog.host.job }}
                            </span>
                            <p class="font-bold">
                                {{ blog.host.username }}
                            </p>
                        </div>
                    </div>

                    <div class="mb-8">
                        <h2 class="font-bold mb-4">お問い合わせフォーム</h2>
                        <p class="text-sm mb-1">送信完了後受領完了の自動送信メールをお送りしております。</p>
                        <p class="text-sm">その後担当者より2〜3営業日以内にメールで返信させていただきます。</p>
                    </div>

                    <div class="grid sm:grid-cols-7 items-center gap-x-10 gap-y-1 mb-5">
                        <label for="sender_name" class="col-span-2 text-[15px] font-bold">
                            お名前
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="col-span-5">
                            <input
                                type="text"
                                name="sender_name"
                                id="sender_name"
                                v-model="sender_name"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full"
                            />
                            <div v-if="senderNameError" class="text-red-500 text-[12px] mt-1">
                                {{ senderNameError }}
                            </div>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-7 items-center gap-x-10 gap-y-1 mb-5">
                        <label for="sender_company" class="col-span-2 text-[15px] font-bold">
                            会社名・屋号
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="col-span-5">
                            <input
                                type="text"
                                name="sender_company"
                                id="sender_company"
                                v-model="sender_company"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full"
                            />
                            <div v-if="senderCompanyError" class="text-red-500 text-[12px] mt-1">
                                {{ senderCompanyError }}
                            </div>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-7 items-center gap-x-10 gap-y-1 mb-5">
                        <label for="sender_email" class="col-span-2 text-[15px] font-bold">
                            メールアドレス
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="col-span-5">
                            <input
                                type="email"
                                name="sender_email"
                                id="sender_email"
                                v-model="sender_email"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full"
                            />
                            <div v-if="senderEmailError" class="text-red-500 text-[12px] mt-1">
                                {{ senderEmailError }}
                            </div>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-7 items-center gap-x-10 gap-y-1 mb-5">
                        <label for="sender_phone" class="col-span-2 text-[15px] font-bold">
                            電話番号
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="col-span-5">
                            <input
                                type="text"
                                name="sender_phone"
                                id="sender_phone"
                                v-model="sender_phone"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full"
                            />
                            <div v-if="senderPhoneError" class="text-red-500 text-[12px] mt-1">
                                {{ senderPhoneError }}
                            </div>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-7 items-center gap-x-10 gap-y-1 mb-4">
                        <label for="message" class="col-span-2 text-[15px] font-bold">
                            お問い合わせ内容
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="col-span-5">
                            <textarea
                                name="message"
                                id="message"
                                v-model="message"
                                class="h-36 w-full border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-300"
                            ></textarea>
                            <div v-if="messageError" class="text-red-500 text-[12px]">
                                {{ messageError }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center gap-8 mt-4">
                    <button 
                        @click.stop="closeModal"
                        class="w-40 rounded-md px-4 py-[9px] text-[14px] sm:text-[15px] border border-primary font-bold hover:opacity-70 transition-all"
                    >
                        キャンセル
                    </button>
                    <button 
                        type="submit"
                        :disabled="isSubmitting"
                        class="w-40 rounded-md px-4 py-[10px] text-[14px] sm:text-[15px] text-white bg-primary font-bold hover:bg-hover-primary transition-all"
                    >
                        {{ isSubmitting ? '送信中...' : '送信する' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        ['isOpen']: Boolean,
        blog: Object
    },
    data() {
        return {
            senderNameError: '',
            senderCompanyError: '',
            senderEmailError: '',
            senderPhoneError: '',
            messageError: '',
            sender_name: '',
            sender_company: '',
            sender_email: '',
            sender_phone: '',
            message: '',
            isSubmitting: false,
        }
    },
    methods: {
        async submitForm() {
            try {
                if (this.sender_name === "") {
                    this.senderNameError = "お名前を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.senderNameError = "";
                }

                if (this.sender_company === "") {
                    this.senderCompanyError = "会社名・屋号を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.senderCompanyError = "";
                }

                if (this.sender_email === "") {
                    this.senderEmailError = "メールアドレスを記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.senderEmailError = "";
                }

                if (this.sender_phone === "") {
                    this.senderPhoneError = "電話番号を記入してください";
                    window.scrollTo(0, 0);
                    return;
                } else if (!/^\d+$/.test(this.sender_phone)) {
                    this.senderPhoneError = "電話番号は半角数字のみで入力してください";
                    window.scrollTo(0, 0);
                    return;
                } else if (this.sender_phone.length !== 10 && this.sender_phone.length !== 11) {
                    this.senderPhoneError = "電話番号は10桁または11桁で入力してください";
                    window.scrollTo(0, 0);
                    return;
                } else {
                    this.senderPhoneError = "";
                }

                if (this.message === "") {
                    this.messageError = "お問い合わせ内容を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.messageError = "";
                }

                this.isSubmitting = true;

                const formData = {
                    receiver: this.blog.host.id,
                    blog: this.blog.id,
                    sender_name: this.sender_name,
                    sender_company: this.sender_company,
                    sender_email: this.sender_email,
                    sender_phone: this.sender_phone,
                    message: this.message,
                }

                await axios.post('/api/v1/offers/create/', formData);

                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();
                window.scrollTo(0,0);
                this.resetFormFields();
                this.$store.commit('setFlashMessage', { message: 'お問い合わせが完了しました', status: 'success' });

            } catch (error) {
                this.$store.commit('setFlashMessage', { message: 'お問い合わせの送信に失敗しました', status: 'error' });
                    setTimeout(() => {
                        this.$store.commit('clearFlashMessage');
                }, 2000);

                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.closeModal();
                this.isSubmitting = false;
                window.scrollTo(0,0);
            }
        },
        resetFormFields() {
            this.sender_name = '';
            this.sender_company = '';
            this.sender_email = '';
            this.sender_phone = '';
            this.message = '';
        },
        closeModal() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 850px;
    width: 95%;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    overflow-y: scroll;
}
.modal-content {
    padding: 32px 0 38px;
    display: flex;
    flex-direction: column;
}
</style>