<template>
    <div>
        <router-link v-bind:to="blog.get_absolute_url" class="grid grid-cols-3 mb-8 gap-3 group">
            <div v-if="blog.get_thumbnail" class="col-span-2 thumbnail rounded-md group-hover:opacity-80 transition-all">
                <img :src="blog.get_thumbnail" class="w-full h-full rounded-md object-cover" />
            </div>
            <div v-else class="col-span-2 thumbnail rounded-md group-hover:opacity-80 transition-all">
                <img src="../../../assets/images/placeholder-image.png" class="w-full h-full rounded-md object-cover" />
            </div>

            <div class="col-span-1 flex flex-col justify-between">
                <div>
                    <div class="font-bold text-xl mb-6">
                        {{ blog.name }}
                    </div>

                    <div class="line-clamp-5 ckImg" v-html="blog.description"></div>
                </div>

                <div class="flex items-center gap-2">
                    <div v-if="user.get_avatar" class="w-12 h-12">
                        <img :src="user.get_avatar" class="rounded-full w-full h-full object-cover" />
                    </div>
                    <div v-else class="w-12 h-12">
                        <img src="../../../assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                    </div>

                    <div class="flex flex-col items-start">
                        <p class="font-bold text-[15px]">
                            {{ user.username }}

                            <span v-if="user.job">
                                <span class="text-xs">／</span>
                                {{ user.job }}
                            </span>
                        </p>
                        <span class="text-negative text-xs inline-block">
                            <timeago :datetime="date"/>
                        </span>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        blog: Object,
    },
    data() {
        return {
            user: {},
            date: this.blog.created,
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        async getUser() {
            const user_uuid = this.$route.params.user_uuid

            await axios
                .get(`/api/v1/${user_uuid}`).then(response => {
                    this.user = response.data
                    document.title =  this.user.username + ' | iNnos Studio'
                }).catch (error => {
                    console.log(error)
                })
        }
    },
}
</script>

<style scoped>
.thumbnail {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}
.thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
