<template>
    <div class="flex flex-col group sm:m-0">
        <router-link v-bind:to="blog.get_absolute_url">

            <div class="group-hover:opacity-80 transition-all">
                <div v-if="blog.get_thumbnail" class="thumbnail" :key="blog.id">
                    <img v-lazy="blog.get_thumbnail" class="w-full h-full object-cover rounded-md" />
                </div>
                <div v-else class="thumbnail">
                    <img src="@/assets/images/placeholder-image.png" class="w-full h-full object-cover rounded-md" />
                </div>
            </div>

            <div class="">
                <h1 class="font-bold text-[22px] mt-4 mb-3 tracking-wider line-clamp-2">
                    {{ blog.name }}
                </h1>

                <router-link v-bind:to="blog.host.get_absolute_url" class="flex items-center gap-2">

                    <div v-if="blog.host.get_avatar">
                        <div  class="w-12 h-12">
                            <img :src="blog.host.get_avatar" class="rounded-full w-full h-full object-cover" />
                        </div>
                    </div>
                    <div v-else>
                        <div class="w-12 h-12">
                            <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                        </div>
                    </div>

                    <div class="flex justify-between items-end w-full">
                        <p class="font-bold text-[15px] flex flex-col items-start">
                            <span v-if="blog.host.job" class="text-xs">
                                {{ blog.host.job }}
                            </span>

                            <span>
                                {{ blog.host.username }}
                            </span>
                        </p>
                    </div>
                </router-link>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        blog: Object
    },
    data() {
        return {
            date: this.blog.created,
        }
    }
}
</script>

<style scoped>
.thumbnail {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

.thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>