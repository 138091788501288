<template>
    <div>
        <div class="hidden lg:block border rounded-md p-3">
            <div>
                <router-link to="/" active-class="active" class="block py-2 px-3 font-bold rounded-md hover:bg-gray-100 transition-all">
                    すべて
                </router-link>
            </div>

            <div v-for="topic in topics" v-bind:key="topic.id">
                <router-link 
                    v-bind:to="topic.get_absolute_url" active-class="active"
                    class="block font-bold rounded-md hover:bg-gray-100 transition-all"
                >
                    <div v-if="topic.blogs.length" class="py-2 px-3 mt-2">
                        {{ topic.name }}
                    </div>
                </router-link>
            </div>
        </div>

        <div class="block lg:hidden overflow-x-auto hidden-scrollbar hidden-scrollbar"> 
            <div class="flex items-center md:justify-center text-[15px] text-negative">
                <router-link to="/" active-class="active-mobile" class="mr-4 whitespace-nowrap p-2 font-bold hover:text-[#000] transition-all">
                    すべて
                </router-link>

                <div v-for="topic in topics" v-bind:key="topic.id">
                    <router-link
                        v-if="topic.blogs.length"
                        v-bind:to="topic.get_absolute_url" active-class="active-mobile"
                        class="mr-4 whitespace-nowrap p-2 font-bold hover:text-[#000] transition-all">
                        {{ topic.name }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        topics: Object
    },
}
</script>

<style scoped>
    .active {
        pointer-events: none;
        background: #25292E;
        color: #fff;
    }
    .active-mobile {
        pointer-events: none;
        color: #000;
    }
</style>