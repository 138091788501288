import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import Login from '../views/Auth/Login'
import Mypage from '../views/Mypage/Mypage'
import Blog from '../views/Blog'
import BlogDetail from '../views/Blog/BlogDetail'
import TopicDetail from '../views/Blog/TopicDetail'
import CreatorDetail from '../views/Blog/CreatorDetail'
import SignUp from '../views/Auth/SignUp'
import Creators from '../views/Admin/Creators'

const routes = [
    {
        path: '/signup',
        name: 'signup',
        component: SignUp,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/mypage/:user_uuid',
        name: 'mypage',
        component: Mypage,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/',
        name: 'blogs',
        component: Blog
    },
    {
        path: '/:topic_slug/:blog_uuid',
        name: 'blogDetail',
        component: BlogDetail
    },
    {
        path: '/topic/:topic_slug',
        name: 'topicDetail',
        component: TopicDetail
    },
    {
        path: '/:user_uuid',
        name: 'creatorDetail',
        component: CreatorDetail
    },
    {
        path: '/admin/creators',
        name: '/creators',
        component: Creators,
        meta: {
            requireLogin: true
        }
    }
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0
            }
        }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
        next({ name: 'login', query: { to: to.path } });
    } else {
        next()
    }
})

export default router
