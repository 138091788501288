<template>
    <div v-if="! isLoading" class="max-w-5xl pt-24 mx-auto flex justify-center">
        <p class="text-xs py-4">
            &copy; {{ currentYear }} iNNos Studio.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: Boolean
    },
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    },
}
</script>