<template>
    <div class="relative w-full" ref="dropdown">
        <div @click="dropdownOpen = !dropdownOpen">
            <div v-if="image" class="cursor-pointer border border-gray-200 rounded-md py-2 px-3 flex justify-between items-center">
                <span>{{ placeholder }}</span>
                <i class="fas fa-chevron-down"></i>
            </div>
            <div v-else class="cursor-pointer border border-gray-200 rounded-md py-2 px-3 flex justify-between items-center">
                <span>{{ selectedItem?.name || placeholder }}</span>
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
        <div 
            v-if="dropdownOpen"
            class="absolute z-10 w-full bg-white mt-1 border border-gray-200 rounded-md max-h-[250px] overflow-auto shadow-md bg-tr"
        >
            <div 
                v-for="iten in items"
                :key="iten.id" 
                @click="selectItem(iten)"
            >
                <div v-if="image" class="flex items-center gap-4 px-4 py-3 hover:bg-gray-100 cursor-pointer border-b border-gray-200 last:border-b-0">
                    <div v-if="iten.get_avatar">
                        <div class="w-10 h-10">
                            <img :src="iten.get_avatar" class="rounded-full w-full h-full object-cover" />
                        </div>
                    </div>
                    <div v-else>
                        <div class="w-10 h-10">
                            <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                        </div>
                    </div>
                    <span>{{ iten.username }}</span>
                </div>
                <div v-else class="flex items-center gap-4 px-4 py-3 hover:bg-gray-100 cursor-pointer border-b border-gray-200 last:border-b-0">
                    <span>{{ iten.name }}</span> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        selectedItem: Object,
        placeholder: String,
        image: Boolean,
    },
    data() {
        return {
            dropdownOpen: false,
        };
    },
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
    },
    unmounted() {
        document.removeEventListener('click', this.handleOutsideClick);
    },
    methods: {
        selectItem(item) {
            this.dropdownOpen = false;
            this.$emit('item-selected', item);
        },
        handleOutsideClick(event) {
            if (!this.$refs.dropdown.contains(event.target)) {
                this.dropdownOpen = false;
            }
        },
    }
}
</script>