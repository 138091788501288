<template>
    <div>
        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="!isLoading">
            <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
                <div class="modal rounded-md px-6 md:px-28">
                    <form @submit.prevent="updateUser" class="modal-content py-8 md:py-10" enctype="multipart/form-data">
                        <div class="flex flex-col items-center justify-center gap-2 mb-[14px]">
                            <label>
                                <div v-if="url" class="w-28 h-28 sm:w-32 sm:h-32 rounded-full">
                                    <img :src="url" class="w-full h-full object-cover rounded-full" />
                                </div>
                                <div v-else class="w-28 h-28 sm:w-32 sm:h-32 rounded-full">
                                    <img
                                        v-if="user.get_avatar"
                                        :src="user.get_avatar"
                                        class="w-full h-full object-cover rounded-full"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/avatar.png"
                                        class="w-full h-full object-cover rounded-full"
                                    />
                                </div>
                            </label>

                            <div class="my-4">
                                <label
                                    class="cursor-pointer py-2 px-4 border rounded-md w-full flex items-center justify-center gap-2 text-[15px] text-center block hover:border-gray-400 transition-all"
                                >
                                    <span>プロフィール写真</span>
                                    <i class="fas fa-upload -mt-[2px]"></i>
                                    <input
                                        name="avatar"
                                        type="file"
                                        ref="preview"
                                        @change="uploadFile"
                                        class="hidden"
                                    />
                                </label>
                                <div></div>
                                <div v-if="fileSizeError" class="text-red-500 text-[12px] text-left mt-2">
                                    {{ fileSizeError }}
                                </div>
                            </div>
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="username" class="col-span-1 text-[15px] sm:text-base whitespace-nowrap">
                                クリエーター名
                                <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                            </label>

                            <div class="col-span-3">
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    v-model="username"
                                    class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full bg-transparent"
                                />
                                <div v-if="usernameError" class="text-red-500 text-[12px] mt-2">
                                    {{ usernameError }}
                                </div>
                            </div>
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="job" class="col-span-1 text-[15px] sm:text-base">
                                肩書き
                                <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                            </label>

                            <div class="col-span-3">
                                <input
                                    type="text"
                                    name="job"
                                    id="job"
                                    v-model="job"
                                    placeholder="ダンサー"
                                    class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 w-full bg-transparent"
                                />

                                <div v-if="jobError" class="text-red-500 text-[12px] mt-2">
                                    {{ jobError }}
                                </div>
                            </div>
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="username" class="col-span-1 text-[15px] sm:text-base whitespace-nowrap">
                                メールアドレス
                                <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                            </label>

                            <div class="col-span-3">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    v-model="email"
                                    class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full bg-transparent"
                                />
                                <div v-if="emailError" class="text-red-500 text-[12px] mt-2">
                                    {{ emailError }}
                                </div>
                            </div>
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="bio" class="col-span-1 text-[15px] sm:text-base">
                                自己紹介
                            </label>

                            <textarea
                                name="bio"
                                id="bio"
                                :value="user.bio" 
                                @input="bio = $event.target.value"
                                placeholder="簡単な経歴、活動状況"
                                class="h-36 col-span-3 border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 bg-transparent"
                            ></textarea>
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="twitter" class="col-span-1 text-[15px] sm:text-base">
                                Twitter
                            </label>

                            <input
                                type="url"
                                name="twitter"
                                id="twitter"
                                :value="user.twitter" 
                                @input="twitter = $event.target.value"
                                placeholder="https://twitter.com/..."
                                class="col-span-3 border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 bg-transparent"
                            />
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="instagram" class="col-span-1 text-[15px] sm:text-base">
                                Instagram
                            </label>

                            <input
                                type="url"
                                name="instagram"
                                id="instagram"
                                :value="user.instagram" 
                                @input="instagram = $event.target.value"
                                placeholder="https://www.instagram.com/..."
                                class="col-span-3 border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 bg-transparent"
                            />
                        </div>

                        <div class="grid sm:grid-cols-4 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                            <label for="youtube" class="col-span-1 text-[15px] sm:text-base">
                                Youtube
                            </label>

                            <input
                                type="url"
                                name="youtube"
                                id="youtube"
                                :value="user.youtube" 
                                @input="youtube = $event.target.value"
                                placeholder="https://www.youtube.com/..."
                                class="col-span-3 border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 bg-transparent"
                            />
                        </div>

                        <div class="flex justify-center gap-8 mt-4 sm:mt-6">
                            <span
                                @click.stop="closeModal"
                                class="w-40 rounded-full px-4 py-[9px] text-[14px] sm:text-[15px] bg-[#353946] border border-gray-200 hover:bg-[#2e3240] hover:text-gray-300 font-bold transition-all flex items-center justify-center cursor-pointer"
                            >
                                キャンセル
                            </span>
                            <button 
                                type="submit"
                                :disabled="isSubmitting"
                                class="w-40 px-4 py-[10px] text-[14px] sm:text-[15px] text-white font-bold rounded-full bg-[#6046E3] hover:bg-[#4c3cb1] hover:text-gray-300 transition-all"
                            >
                                {{ isSubmitting ? '保存中...' : '保存する' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from "@/components/Loading"
import Dropdown from "@/components/Common/Dropdown"

export default {
    props: {
        ['isOpen']: Boolean,
        user: Object,
    },
    components: {
        Loading,
        Dropdown
    },
    data() {
        return {
            username: this.user.username,
            email: this.user.email,
            job: this.user.job,
            bio: this.user.bio,
            twitter: this.user.twitter,
            instagram: this.user.instagram,
            youtube: this.user.youtube,
            url: "",
            usernameError: "",
            jobError: "",
            emailError: "",
            fileSizeError: '',
            isSubmitting: false,
            selectedCategory: {},
            isLoading: true,
            isLoadingOverlay: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.getCategories();
        });
    },
    methods: {
        async getCategories() {
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                const response = await axios.get('/api/v1/categories');
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.categories = [];
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        uploadFile() {
            const file = this.$refs.preview.files[0];
            if (file.size > 5 * 1024 * 1024) {
                this.fileSizeError = 'プロフィール写真は5MB以下を選択してください';
                return;
            }
            this.url = URL.createObjectURL(file);
        },
        async updateUser() {
            try {
                if (this.username === "" || this.username === null) {
                    this.usernameError = "クリエーター名を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.usernameError = "";
                }
                if (this.job === "" || this.job === null) {
                    this.jobError = "肩書きを記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.jobError = "";
                }

                if (this.email === "" || this.email === null) {
                    this.emailError = "メールアドレスを記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.emailError = "";
                }

                this.isSubmitting = true;
                const user_uuid = this.user.uuid;

                await axios.patch(`/api/v1/innos-creators/update/${user_uuid}`, {
                    avatar: this.$refs.preview.files[0],
                    username: this.username,
                    email: this.email,
                    job: this.job,
                    bio: this.bio,
                    twitter: this.twitter,
                    instagram: this.instagram,
                    youtube: this.youtube,
                },{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.$emit('update-user');

                this.$store.commit('setFlashMessage', { message: 'プロフィールを更新しました', status: 'success' });
                    setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'プロフィールの更新に失敗しました'; // デフォルトのエラーメッセージ

                this.$store.commit('setFlashMessage', { message: errorMessage, status: 'error' });
                    setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();

                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.user = null;
            }
        },
        closeModal() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 850px;
    width: 95%;
    max-height: 95%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: #23202C;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #353945;
}
.modal-content {
    display: flex;
    flex-direction: column;
}
.image-container {
    aspect-ratio: 16 / 9;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
}
.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>