<template>
    <div class="bg-white text-color">
        <BlogHeader />

        <div class="px-4 sm:py-20">
            <div class="max-w-[450px] mx-auto py-8 px-4 sm:px-8 border border-gray-100 my-10 sm:my-20 rounded-md shadow-lg">
                <form @submit.prevent="submitForm">

                    <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                        <label for="email">
                            メールアドレス:
                        </label>
                        <input v-model="email" type="email" name="email" id="email"
                            class="border p-2 outline-none rounded-md"
                        />
                    </div>

                    <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                        <label for="password">
                            パスワード:
                        </label>
                        <input v-model="password" type="password" name="password" id="password"
                            class="border p-2 outline-none rounded-md"
                        />
                    </div>

                    <div class="text-red-500 text-sm" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="text-center mt-8 sm:mt-10">
                        <button 
                            type="submit"
                            :disabled="isSubmitting"
                            class="py-[10px] tracking-wider w-full font-bold text-white cursor-pointer rounded-md bg-primary hover:bg-hover-primary transition-all"
                        >
                            {{ isSubmitting ? '送信中...' : 'ログイン' }}
                        </button>
                    </div>

                    <router-link to="/signup" class="mt-6 sm:mt-8 block text-center text-sm text-blue-500 font-bold hover:underline">
                        アカウントをお持ちでない方はこちら
                    </router-link>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import BlogHeader from '@/components/BlogHeader'

export default {
    name: 'login',
    data() {
        return {
            email: '',
            password: '',
            errors: [],
            isLoading: true,
            isLoadingOverlay: false,
            isSubmitting: false,
        }
    },
    created() {
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
    },
    components: {
        BlogHeader,
    },
    mounted() {
        document.title = 'Login | iNnos Studio'
    },
    methods: {
        async submitForm() {
            this.isSubmitting = true;
            this.errors = []

            if (this.email === '') {
                this.errors.push('メールアドレスを記入して下さい。')
            } else if (this.password === '') {
                this.errors.push('パスワードを記入して下さい。')
            } 

            if (!this.errors.length) {
                const formData = {
                    email: this.email,
                    password: this.password
                }
                try {
                    const response = await axios.post("/api/v1/token/login/", formData);
                    const token = response.data.auth_token;
                    this.$store.commit('setToken', token);

                    axios.defaults.headers.common["Authorization"] = "Token " + token;
                    localStorage.setItem("token", token);
                    const toPath = this.$route.query.to || '/';
                    this.$router.push(toPath);
                } catch (error) {
                    if (error.response) {
                        this.errors.push('認証情報が間違っています');
                    }
                }
            }
            this.isSubmitting = false;
        }
    }
}
</script>