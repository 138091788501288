<template>
    <div v-if="blogs.length" class="mt-14">
        <hr class="mb-12" />
        <h3 class="text-xl sm:text-2xl font-bold mb-7">関連する記事</h3>

        <div class="lg:col-span-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-10 sm:gap-y-8">
            <BlogCard
                v-for="blog in blogs" 
                v-bind:key="blog.id"
                v-bind:blog="blog"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import BlogCard from '@/components/Blog/BlogCard'

export default {
    components: {
        BlogCard
    },
    props: ['topic_slug', 'blog_uuid'],
    data() {
        return {
            blogs: [],
        }
    },
    mounted() {
        this.getBlogs(this.topic_slug, this.blog_uuid);
    },
    watch: {
        $route(to, from) {
            this.getBlogs(to.params.topic_slug, to.params.blog_uuid);
        }
    },
    methods: {
        async getBlogs(topic_slug, blog_uuid) {
            try {
                const response = await axios.get(`/api/v1/${topic_slug}/${blog_uuid}/related-blogs`);
                this.blogs = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.blogs = [];
            }
        },
    },
}
</script>
