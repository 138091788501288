import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.css'
import axios from 'axios'
import timeago from 'vue-timeago3'
import { ja } from 'date-fns/locale'
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import VueLazyload from 'vue-lazyload';

const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction ? 'https://innos-api.herokuapp.com' : 'http://127.0.0.1:8000';

axios.defaults.baseURL = baseURL;

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const timeagoOptions = {
    converterOptions: {
        includeSeconds: false,
    },
    locale: ja,
}  

createApp(App)
    .use(store)
    .use(router, axios)
    .use(timeago, timeagoOptions)
    .use(VueScrollTo)
    .use(VueLazyload, {
        loading: require('./assets/images/loading-spinner.gif'),
        attempt: 1
    })
    .use(VueGtag, {
        config: { id: 'G-19QW0DXJHS' }
    }, router)
    .mount('#app')
