<template>
    <nav :class="{ 'scrolled': !view.atTopOfPage }" class="sticky top-0 w-full z-10 tracking-wider transition-all h-[78px]"> 
        <div class="mx-auto max-w-7xl h-full flex justify-between items-center px-4 md:px-6 font-bold">

            <button v-scroll-to="'#top'" class='inline-block font-bold flex items-center cursor-pointer w-[110px] md:w-[130px]'>
                <img src="../assets/images/logo.png" alt="logo" class='w-full h-full object-cover' />
            </button>

            <ul class='list-none flex-row justify-between items-center'>
                <li class="hidden md:inline-block">
                    <button v-scroll-to="'#concept'" class="ml-10 cursor-pointer hover:text-primary transition-all">
                        Concept
                    </button>
                </li>
                <li class="hidden md:inline-block">
                    <button v-scroll-to="'#future'" class="ml-10 cursor-pointer hover:text-primary transition-all">
                        Future
                    </button>
                </li>
                <li class="hidden md:inline-block">
                    <button v-scroll-to="'#plan'" class="ml-10 cursor-pointer hover:text-primary transition-all">
                        Plan
                    </button>
                </li>
                <li class="hidden md:inline-block">
                    <button v-scroll-to="'#creators'" class="ml-10 cursor-pointer hover:text-primary transition-all">
                        Creator's
                    </button>
                </li>
                <li class="inline-block">
                    <a href="/blogs" target="_blank" rel="noopener noreferrer" :class="{ 'scrolled': !view.atTopOfPage }" class="btn ml-10 px-4 py-2 cursor-pointer rounded-md bg-primary hover:bg-hover-primary transition-all">
                        Blog
                    </a>
                </li>
            </ul>

            <!-- mobile menu -->
            <!-- <button class="static md:hidden" @click="showMobileMenu = !showMobileMenu">
                <i class="fas fa-bars text-2xl"></i>
            </button>
            <div v-if="showMobileMenu" class='fixed top-0 right-0 z-20 w-full'>
                <ul class='p-4 w-full h-screen shadow-2xl list-none sidebar-bg animate-slide-in'>
                    <li class='text-xl w-full my-2 text-right'>
                        <button @click="showMobileMenu = !showMobileMenu">
                            <i class="fas fa-times text-2xl px-2"></i>
                        </button>
                    </li>

                    <li class="w-full text-center text-lg"> 
                        <router-link to='#concept' class="w-full py-6 inline-block cursor-pointer hover:text-primary transition-all">
                            Concept
                        </router-link>
                    </li>
                    <li class="w-full text-center text-lg"> 
                        <router-link to='#future' class="w-full py-6 inline-block cursor-pointer hover:text-primary transition-all">
                            Future
                        </router-link>
                    </li>
                    <li class="w-full text-center text-lg"> 
                        <router-link to='#plan' class="w-full py-6 inline-block cursor-pointer hover:text-primary transition-all">
                            Plan
                        </router-link>
                    </li>
                    <li class="w-full text-center text-lg"> 
                        <router-link to='#creators' class="w-full py-6 inline-block cursor-pointer hover:text-primary transition-all">
                            Creator's
                        </router-link>
                    </li>
                    <li class="w-full text-center text-lg"> 
                        <a href="/blogs" target="_blank" rel="noopener noreferrer" class="w-full py-6 inline-block cursor-pointer hover:text-primary transition-all">
                            Blog
                        </a>
                    </li>
                </ul>
            </div> -->
        </div>
    </nav>
</template>

<script>
export default {
    data () {
        return {
            view: {
                atTopOfPage: true
            },
            showMobileMenu: false
        }
    },
    beforeMount () {
        window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
        '$route': function (n, o) {
            if (n.hash.match(/^#/)) {
                document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
            }
        }
    },
    mounted () {
        if (this.$route.hash.match(/^#/)) {
        document.getElementById(this.$route.hash.replace(/^#/, '')).scrollIntoView()
        }
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > 0) {
                if(this.view.atTopOfPage) this.view.atTopOfPage = false
            } else {
                if (!this.view.atTopOfPage) this.view.atTopOfPage = true
            }
        }
    }
}
</script>

<style scoped>
    nav.scrolled {
        @apply shadow-lg;
        background: rgb(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    .btn.scrolled {
        @apply rounded-md;
        background: #fff;
        color: #25292F;
        padding: 8px 16px;
    }
    .btn.scrolled:hover {
        background: rgb(200, 200, 200);
        transition: 0.2s;
    }
</style>