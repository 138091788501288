<template>
    <div class="mb-2">
        <div class="lg:border rounded-md px-3 py-5">
            <div class="">
                <div class="block font-bold">
                    <div v-if="user.get_avatar" class="w-20 h-20 lg:mx-auto mb-4">
                        <img :src="user.get_avatar" class="rounded-full w-full h-full object-cover" />
                    </div>
                    <div v-else class="w-20 h-20 mx-auto mb-4">
                        <img src="../../../assets/images/avatar.png" class="rounded-full w-full h-full object-cover group-hover:opacity-80 transition-all" />
                    </div>

                    <div class="text-xl mb-4 lg:text-center">
                        <span class="hidden lg:block text-negative text-xs">{{ user.job }}</span>
                        {{ user.username }}
                        <span class="lg:hidden"> | {{ user.job }}</span>
                    </div>

                    <div class="font-normal text-sm leading-6">
                        {{ user.bio }}
                    </div>

                    <div class="flex items-center lg:justify-center gap-8 mt-4">
                        <a v-if="user.twitter" :href="user.twitter" target="_blank" rel="noopener noreferrer" class="inline-block hover:opacity-80 transition-all">
                            <i class="fab fa-twitter text-[#1C9AEE] text-2xl"></i>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank" rel="noopener noreferrer" class="inline-block hover:opacity-80 transition-all">
                            <i class="fab fa-instagram text-[#E1306C] text-2xl"></i>
                        </a>
                        <a v-if="user.youtube" :href="user.youtube" target="_blank" rel="noopener noreferrer" class="inline-block hover:opacity-80 transition-all">
                            <i class="fab fa-youtube text-[#FF0000] text-2xl"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: Object
    },
}
</script>