<template>
    <div>
        <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
            <div class="modal rounded-md px-6 md:px-20">
                <form @submit.prevent="submitForm" class="modal-content py-8 md:py-12">
                    <div class="mb-5 sm:mb-7">
                        <label for="name" class="text-[15px] sm:text-base whitespace-nowrap">
                            カテゴリー名
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>

                        <div class="mt-2">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                v-model="name"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full bg-transparent"
                            />
                            <div v-if="nameError" class="text-red-500 text-[12px] mt-2">
                                {{ nameError }}
                            </div>
                        </div>
                    </div>

                    <div class="mb-5 sm:mb-7">
                        <label for="slug" class="text-[15px] sm:text-base">
                            スラッグ（ パーマリンク ）
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>

                        <div class="mt-2">
                            <input
                                type="text"
                                name="slug"
                                id="slug"
                                v-model="slug"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 w-full bg-transparent"
                            />

                            <div v-if="slugError" class="text-red-500 text-[12px] mt-2">
                                {{ slugError }}
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center gap-8 mt-4 sm:mt-6">
                        <span
                            @click.stop="closeModal"
                            class="w-40 rounded-full px-4 py-[9px] text-[14px] sm:text-[15px] bg-[#353946] border border-gray-200 hover:bg-[#2e3240] hover:text-gray-300 font-bold transition-all flex items-center justify-center cursor-pointer"
                        >
                            キャンセル
                        </span>
                        <button 
                            type="submit"
                            :disabled="isSubmitting"
                            class="py-3 tracking-wider w-40 font-bold text-white cursor-pointer rounded-full bg-[#6046E3] hover:bg-[#4c3cb1] hover:text-gray-300 transition-all"
                        >
                            {{ isSubmitting ? '登録中...' : '登録する' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        ['isOpen']: Boolean,
    },
    data() {
        return {
            name: '',
            slug: '',
            nameError: "",
            slugError: "",
            isSubmitting: false,
        };
    },
    methods: {
        async submitForm() {
            try {
                if (this.name === "" || this.name === null) {
                    this.nameError = "カテゴリー名を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.nameError = "";
                }
                if (this.slug === "" || this.slug === null) {
                    this.slugError = "スラッグを記入してください";
                    window.scrollTo(0,0);
                    return;
                } else if (!/^[a-z0-9\-_]+$/.test(this.slug)) {
                    // スラッグが小文字の半角英数字、ハイフン、アンダースコアのみで構成されているかチェック
                    this.slugError = "スラッグは小文字の半角英数字、ハイフン、アンダースコアのみで記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.slugError = "";
                }

                this.isSubmitting = true;

                const formData = {
                    name: this.name,
                    slug: this.slug
                }

                await axios.post("/api/v1/innos-creators/categories/create/", formData);

                this.$store.commit('setFlashMessage', { message: 'カテゴリーを登録しました', type: 'success' });
                this.$emit('update-categories');

                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'カテゴリーの登録に失敗しました';

                this.$store.commit('setFlashMessage', { message: errorMessage, type: 'error' });
                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();

                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            }
        },
        closeModal() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 750px;
    width: 95%;
    max-height: 95%;
    background-color: #23202C;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #353945;
}
.modal-content {
    display: flex;
    flex-direction: column;
}
</style>