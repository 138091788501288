<template>
    <div class="bg-white text-color">
        <BlogHeader />

        <FlashMessage />

        <div class="px-4 sm:py-20">
            <div class="max-w-[450px] mx-auto py-8 px-4 sm:px-8 border border-gray-100 my-10 sm:my-20 rounded-md shadow-lg">
                <form @submit.prevent="submitForm">

                    <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                        <label for="username">
                            ユーザー名:
                        </label>
                        <input v-model="username" type="text" name="username" id="username"
                            class="border p-2 outline-none rounded-md"
                        />
                    </div>

                    <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                        <label for="email">
                            メールアドレス:
                        </label>
                        <input v-model="email" type="email" name="email" id="email"
                            class="border p-2 outline-none rounded-md"
                        />
                    </div>

                    <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                        <label for="password">
                            パスワード:
                        </label>
                        <input v-model="password" type="password" name="password" id="password"
                            class="border p-2 outline-none rounded-md"
                        />
                    </div>

                    <div class="flex flex-col gap-2 mb-6 sm:mb-8">
                        <label for="password2">
                            確認用パスワード:
                        </label>
                        <input v-model="password2" type="password" id="password2"
                            class="border p-2 outline-none rounded-md"
                        />
                    </div>

                    <div class="text-red-500 text-sm" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="text-center mt-8 sm:mt-10">
                        <button 
                            type="submit"
                            :disabled="isSubmitting"
                            class="py-[10px] tracking-wider w-full font-bold text-white cursor-pointer rounded-md bg-primary hover:bg-hover-primary transition-all"
                        >
                            {{ isSubmitting ? '送信中...' : '新規登録' }}
                        </button>
                    </div>

                    <router-link to="/login" class="mt-6 sm:mt-8 block text-sm text-center text-blue-500 font-bold hover:underline">
                        ログイン画面へ
                    </router-link>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import BlogHeader from '@/components/BlogHeader'
import FlashMessage from '@/components/FlashMessage.vue'

export default {
    name: 'signUp',
    data() {
        return {
            username: '',
            email: '',
            password: '',
            password2: '',
            errors: [],
            isSubmitting: false,
        }
    },
    components: {
        BlogHeader,
        FlashMessage,
    },
    mounted() {
        document.title = 'Sign Up | iNnos Studio'
    },
    methods: {
        async submitForm() {
            this.isSubmitting = true;
            this.errors = []

            if (this.username === '') {
                this.errors.push('ユーザー名を記入して下さい。')
            } else if (this.email === '') {
                this.errors.push('メールアドレスを記入して下さい。')
            } else if (this.password === '') {
                this.errors.push('パスワードを記入して下さい。')
            } else if (this.password2 === '') {
                this.errors.push('確認用パスワードを記入して下さい。')
            } 
            else if (this.password !== this.password2) {
                this.errors.push('パスワードが一致しません。')
            }

            if (!this.errors.length) {
                const formData = {
                    username: this.username,
                    email: this.email,
                    password: this.password
                }
                try {
                    const response = await axios.post("/api/v1/register/", formData);
                    this.$store.commit('setFlashMessage', { message: '登録が完了しました', type: 'success' });
                    setTimeout(() => {
                        this.$store.commit('clearFlashMessage');
                        this.$router.push({ path: '/login', query: { email: response.data.email } });
                    }, 1500);
                } catch (error) {
                    if (error.response && error.response.data) {
                        // APIからのエラーメッセージを処理
                        const errors = error.response.data;
                        for (const key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                const errorMessages = errors[key];
                                if (Array.isArray(errorMessages)) {
                                    errorMessages.forEach(message => this.errors.push(message));
                                } else {
                                    this.errors.push(errorMessages);
                                }
                            }
                        }
                    } else {
                        this.$store.commit('setFlashMessage', { message: '登録に失敗しました', status: 'error' });
                        setTimeout(() => {
                            this.$store.commit('clearFlashMessage');
                        }, 2000);
                    }
                }
            }
            this.isSubmitting = false;
        }
    }
}
</script>