import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated: false,
    token: '',
    flashMessage: null,
    flashMessageStatus: null,
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      } 
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
    setFlashMessage(state, { message, status }) {
      state.flashMessage = message;
      state.flashMessageStatus = status;
    },
    clearFlashMessage(state) {
      state.flashMessage = '';
      state.flashMessageStatus = '';
    },
  },
  actions: {
  },
  modules: {
  }
})
