<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
        <div class="modal rounded-md px-6 md:px-28">
            <form @submit.prevent="submitForm" class="modal-content">
                <h1 class="text-lg sm:text-xl font-bold text-center">
                    クリエイターにメッセージを送る
                </h1>

                <div>
                    <div class="flex justify-center gap-4 mb-8 mt-7">
                        <div v-if="user.get_avatar" class="w-16 h-16">
                            <img :src="user.get_avatar" class="rounded-full w-full h-full object-cover" />
                        </div>
                        <div v-else class="w-16 h-16">
                            <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                        </div>

                        <div class="flex flex-col items-start justify-center gap-1">
                            <span class="text-negative text-xs font-bold">
                                {{ user.job }}
                            </span>
                            <p class="font-bold">
                                {{ user.username }}
                            </p>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-9 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                        <label for="sender_email" class="col-span-2 text-[15px] font-bold">
                            差出人
                        </label>
                        <div class="col-span-7">
                            <input
                                type="email"
                                name="sender_email"
                                id="sender_email"
                                v-model="sender_email"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full bg-transparent"
                            />
                            <div v-if="emailError" class="text-red-500 text-[12px] mt-2">
                                {{ emailError }}
                            </div>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-9 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                        <label for="sender_company" class="col-span-2 text-[15px] font-bold">
                            件名
                        </label>
                        <div class="col-span-7">
                            <input
                                type="text"
                                name="subject"
                                id="subject"
                                v-model="subject"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full bg-transparent"
                            />
                            <div v-if="subjectError" class="text-red-500 text-[12px] mt-2">
                                {{ subjectError }}
                            </div>
                        </div>
                    </div>

                    <div class="grid sm:grid-cols-9 items-center gap-x-10 gap-y-1 mb-4 sm:mb-6">
                        <label for="message" class="col-span-2 text-[15px] font-bold">
                            メッセージ
                        </label>
                        <div class="col-span-7">
                            <textarea
                                name="message"
                                id="message"
                                v-model="message"
                                class="h-40 w-full border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-gray-500 bg-transparent"
                            ></textarea>
                            <div v-if="messageError" class="text-red-500 text-[12px]">
                                {{ messageError }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-center gap-8 mt-4 sm:mt-6">
                    <button 
                        @click.stop="closeModal"
                        class="w-40 rounded-full px-4 py-[9px] text-[14px] sm:text-[15px] bg-[#353946] border border-gray-200 hover:bg-[#2e3240] hover:text-gray-300 font-bold transition-all"
                    >
                        キャンセル
                    </button>
                    <button 
                        type="submit"
                        :disabled="isSubmitting"
                        class="w-40 px-4 py-[10px] text-[14px] sm:text-[15px] text-white font-bold rounded-full bg-[#6046E3] hover:bg-[#4c3cb1] hover:text-gray-300 transition-all"
                    >
                        {{ isSubmitting ? '送信中...' : '送信する' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        ['isOpen']: Boolean,
        user: Object
    },
    data() {
        return {
            subjectError: '',
            emailError: '',
            messageError: '',
            sender_email: 'info@innos.studio',
            subject: '',
            message: '',
            isSubmitting: false,
        }
    },
    methods: {
        async submitForm() {
            try {
                if (this.sender_email === "" || this.sender_email === null) {
                    this.emailError = "差出人を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.emailError = "";
                }

                if (this.subject === "" || this.subject === null) {
                    this.subjectError = "件名を記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.subjectError = "";
                }

                if (this.message === "" || this.message === null) {
                    this.messageError = "メッセージを記入してください";
                    window.scrollTo(0,0);
                    return;
                } else {
                    this.messageError = "";
                }

                this.isSubmitting = true;

                const formData = {
                    receiver: this.user.id,
                    sender_email: this.sender_email,
                    subject: this.subject,
                    message: this.message,
                }

                await axios.post('/api/v1/innos-creators/send-message/', formData);

                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();
                window.scrollTo(0,0);
                this.resetFormFields();
                this.$store.commit('setFlashMessage', { message: 'メッセージの送信が完了しました', status: 'success' });

            } catch (error) {
                this.$store.commit('setFlashMessage', { message: 'メッセージの送信に失敗しました', status: 'error' });
                    setTimeout(() => {
                        this.$store.commit('clearFlashMessage');
                }, 2000);

                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.closeModal();
                this.isSubmitting = false;
                window.scrollTo(0,0);
            }
        },
        resetFormFields() {
            this.sender_email = '';
            this.subject = '';
            this.message = '';
        },
        closeModal() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 750px;
    width: 95%;
    background-color: #23202C;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #353945;
}
.modal-content {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
}
</style>