<template>
    <transition name="fade">
        <div 
            v-if="flashMessage && isRendered" 
            class="flash-message py-[12px] sm:py-[14px] px-[18px]" 
            :class="{'error': flashMessageStatus === 'error'}"
        >
            {{ flashMessage }}
        </div>
    </transition>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'FlashMessage',
    setup() {
        const store = useStore();
        const flashMessage = computed(() => store.state.flashMessage);
        const flashMessageStatus = computed(() => store.state.flashMessageStatus);
        const isRendered = ref(false);

        onMounted(() => {
            setTimeout(() => {
                isRendered.value = true;
            }, 0);
        });

        return {
            flashMessage,
            flashMessageStatus,
            isRendered
        };
    },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s, transform 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform: translateX(100px);
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
    transform: translateX(0);
}
.flash-message {
    position: fixed;
    top: 78px;
    right: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.8);

    color: white;
}
.flash-message.error {
    background-color: #fc3852;
}
</style>