<template>
    <div class="bg-white text-color">
        <BlogHeader />

        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="! isLoading" class="max-w-7xl mx-auto pt-4 lg:pt-8 px-4 md:px-6 aaa">
            <div class="grid grid-cols-1 lg:grid-cols-8 gap-4 lg:gap-8 pb-10">

                <div class="lg:col-span-2">
                    <CreatorProf 
                        v-bind:user="user"
                    />
                </div>

                <div v-if="user.blogs.length" class="lg:col-span-6">
                    <CreatorFirstBlog
                        v-for="blog in user.blogs.slice(0, 1)" 
                        v-bind:key="blog.id" 
                        v-bind:blog="blog"
                        class="hidden lg:block"
                    />

                    <div class="lg:col-span-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
                        <BlogCard
                            v-for="blog in user.blogs.slice(0, 1)" 
                            v-bind:key="blog.id" 
                            v-bind:blog="blog"
                            class="lg:hidden"
                        />
                        <BlogCard
                            v-for="blog in user.blogs.slice(1)" 
                            v-bind:key="blog.id"
                            v-bind:blog="blog"
                        />
                    </div>
                </div>

                <div v-else class="lg:col-span-6">
                    <p class="font-bold sm:text-lg">
                        {{ user.username }}さんの更新情報はありません
                    </p>

                    <router-link to="/blogs" class="block w-[300px] sm:w-[400px] mx-auto mt-20">
                        <img src="../../assets/images/img.svg" alt="logo" class='w-full h-full object-cover' />
                    </router-link>
                </div>

            </div>
        </div>

        <!-- <Footer v-if="! isLoading" /> -->
        <div class="mt-10"></div>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import BlogHeader from '@/components/BlogHeader'
import BlogCard from '@/components/Blog/BlogCard'
import CreatorProf from '@/components/Blog/Creator/CreatorProf'
import CreatorFirstBlog from '@/components/Blog/Creator/CreatorFirstBlog'
import Footer from '@/components/Footer'

export default {
    name: 'creatorDetail',
    data() {
        return {
            user: {
                blogs: []
            },
            isLoading: true,
            isLoadingOverlay: false
        }
    },
    components: {
        Loading,
        BlogHeader,
        BlogCard,
        CreatorProf,
        CreatorFirstBlog,
        Footer
    },
    mounted() {
        this.$nextTick(() => {
            this.getUser()
        })
    },
    computed: {
        randomBlog() {
            const randomIndex = Math.floor(Math.random() * this.user.blogs.length)
            return this.user.blogs[randomIndex]
        }
    },
    methods: {
        async getUser() {
            this.isLoading = true;
            this.isLoadingOverlay = true;

            try {
                const user_uuid = this.$route.params.user_uuid;
                const response = await axios.get(`/api/v1/${user_uuid}`);

                this.user = response.data;
                document.title =  this.user.username + ' | iNnos Studio';

                await this.$nextTick();
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.blogs = [];
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
    },
}
</script>

<style scoped>
    .ckImg img {
        display: none;
    }
</style>