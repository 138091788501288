<template>
    <div class="gradient-bg text-white font-[Montserrat] tracking-wider">

        <router-view />

    </div>
</template>

<script>
import axios from 'axios'
import Header from '@/components/Header.vue'

export default {
    components: {
        Header,
    },
    beforeCreate() {
        this.$store.commit('initializeStore')
        const token = this.$store.state.token
        if (token) {
            axios.defaults.headers.common['Authorization'] = "Token " + token
        } else {
            axios.defaults.headers.common['Authorization'] = ""
        }
    },
}
</script>