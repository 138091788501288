<template>
    <div>
        <table class="w-full">
            <thead>
                <tr class="bg-[#353946]">
                    <th>No.</th>
                    <th>トピック名</th>
                    <th>スラッグ（ パーマリンク ）</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(topic, index) in topics" :key="topic.id" class="border-b border-gray-200">
                    <td>{{ topics.length - index }}</td>
                    <td>{{ topic.name }}</td>
                    <td>/topic/{{ topic.slug }}</td>
                    <td>
                        <div class="flex gap-[10px]">
                            <button 
                                class="text-sm bg-green-600 hover:bg-green-700 rounded-full w-8 h-8 transition-all hover:text-gray-200"
                                @click="editTopic(topic)"
                            >
                                <i class="fas fa-edit"></i>
                            </button>
                            <button 
                                class="text-sm bg-red-600 hover:bg-red-700 rounded-full w-8 h-8 transition-all hover:text-gray-200"
                                @click="deleteTopic(topic.id)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "topics-table",
    props: {
        topics: {
            type: Array,
            required: true
        },
    },
    methods: {
        editTopic(topic) {
            this.$emit('edit-topic', topic);
        },
        deleteTopic(topicId) {
            this.$emit('delete-topic', topicId);
        },
    }
}
</script>
