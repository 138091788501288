<template>
    <div class="loading-overlay">
        <div class="loading-dots">
        <span></span>
        <span></span>
        <span></span>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

export default {
    components: {
        Loading
    },
}
</script>

<style>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: loadingOverlay 0.3s ease-out;
}

.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-dots span {
    display: inline-block;
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    animation: loading 0.5s ease-in-out infinite alternate;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loading {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.5);
    }
}

@keyframes loadingOverlay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>