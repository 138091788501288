<template>
    <div class="bg-[#22212C] text-white h-[100vh] overflow-y-hidden">
        <FlashMessage />

        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="!isLoading" class="max-w-[1560px] mx-auto pt-10 sm:pt-16 px-4 md:px-6">
            <div class="w-full">
                <div v-if="users.length > 0">
                    <div class="flex items-center gap-4">
                        <div 
                            class="flex justify-center items-center w-10 h-10 rounded-full bg-[#353946] border border-gray-200 hover:bg-[#2e3240] hover:text-gray-300 transition-all cursor-pointer"
                            @click="goBack"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                            </svg>
                        </div>
                        <div class="flex justify-between items-center w-full">
                            <h1 class="text-xl sm:text-2xl font-bold">iNnos 会員管理</h1>
                            <button
                                class="text-sm font-bold rounded-full w-40 p-3 transition-all bg-[#6046e3] hover:bg-[#4c3cb1] hover:text-gray-300"
                                @click.stop.prevent="showRegisterCreatorModal=true"
                                v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}"
                            >
                                クリエーターの登録
                            </button>
                            <button
                                class="text-sm font-bold rounded-full w-40 p-3 transition-all bg-[#6046e3] hover:bg-[#4c3cb1] hover:text-gray-300"
                                @click.stop.prevent="showRegisterTopicModal=true"
                                v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}"
                            >
                                トピックの登録
                            </button>
                            <button
                                class="text-sm font-bold rounded-full w-40 p-3 transition-all bg-[#6046e3] hover:bg-[#4c3cb1] hover:text-gray-300"
                                @click.stop.prevent="showRegisterCategoryModal=true"
                                v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}"
                            >
                                カテゴリーの登録
                            </button>
                        </div>
                    </div>

                    <ul class="flex py-4">
                        <li class="w-32 text-center">
                            <a 
                                class="text-sm font-bold uppercase p-3 block cursor-pointer hover:text-pink-600" 
                                v-on:click="toggleTabs(1)" 
                                v-bind:class="{'text-white': openTab !== 1, 'border-b-2 border-pink-600 hover:text-white': openTab === 1}"
                            >
                                クリエーター
                            </a>
                        </li>
                        <li class="w-32 text-center">
                            <a 
                                class="text-sm font-bold uppercase p-3 block cursor-pointer hover:text-pink-600" 
                                v-on:click="toggleTabs(2)" 
                                v-bind:class="{'text-white': openTab !== 2, 'border-b-2 border-pink-600 hover:text-white': openTab === 2}"
                            >
                                トピック
                            </a>
                        </li>
                        <li class="w-32 text-center">
                            <a 
                                class="text-sm font-bold uppercase p-3 block cursor-pointer hover:text-pink-600" 
                                v-on:click="toggleTabs(3)" 
                                v-bind:class="{'text-white': openTab !== 3, 'border-b-2 border-pink-600 hover:text-white': openTab === 3}"
                            >
                                カテゴリー
                            </a>
                        </li>
                    </ul>

                    <!-- クリエーター Table -->
                    <div 
                        class="table-container w-full overflow-x-auto border border-gray-200"
                        v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}"
                    >
                        <CreatorsTable 
                            :users="users" 
                            @edit-user="editUser"
                            @delete-user="deleteUser"
                            @send-message="sendMessage"
                        />
                    </div>

                    <!-- トピック Table -->
                    <div 
                        class="table-container w-full overflow-x-auto border border-gray-200"
                        v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}"
                    >
                        <TopicsTable 
                            :topics="topics" 
                            @edit-topic="editTopic"
                            @delete-topic="deleteTopic"
                        />
                    </div>

                    <!-- カテゴリー Table -->
                    <div 
                        class="table-container w-full overflow-x-auto border border-gray-200"
                        v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}"
                    >
                        <CategoriesTable 
                            :categories="categories" 
                            @edit-category="editCategory"
                            @delete-category="deleteCategory"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="mt-10 flex flex-col items-center">
                        <h1 class="text-xl sm:text-2xl font-bold text-center">
                            このページを閲覧する権限がありません
                        </h1>
                        <button
                            class="mt-10 text-sm bg-[#6046e3] font-bold rounded-full px-10 py-3 transition-all hover:bg-[#4c3cb1] hover:text-gray-300"
                            @click="goBack"
                        >
                            戻る
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-wrapper">
            <!-- クリエータ -->
            <transition name="fade" mode="out-in">
                <RegisterCreator
                    v-if="showRegisterCreatorModal" 
                    :isOpen="showRegisterCreatorModal"
                    @close="showRegisterCreatorModal=false"
                    @update-users="getUsers"
                />
            </transition>
            <transition name="fade" mode="out-in">
                <ProfileEditModal
                    v-if="showProfileEditModal" 
                    :isOpen="showProfileEditModal"
                    :user="currentUser"
                    @close="showProfileEditModal=false" 
                    @update-user="refreshUsers"
                />
            </transition>
            <transition name="fade" mode="out-in">
                <MessageModal 
                    v-if="showMessageModal"
                    :isOpen="showMessageModal"
                    :user="currentUser"
                    @close="showMessageModal=false" 
                />
            </transition>
            <!-- トピック -->
            <transition name="fade" mode="out-in">
                <RegisterTopic
                    v-if="showRegisterTopicModal" 
                    :isOpen="showRegisterTopicModal"
                    @close="showRegisterTopicModal=false"
                    @update-topics="getTopics"
                />
            </transition>
            <transition name="fade" mode="out-in">
                <TopicEditModal
                    v-if="showTopicEditModal" 
                    :isOpen="showTopicEditModal"
                    :topic="currentTopic"
                    @close="showTopicEditModal=false" 
                    @update-topic="refreshTopics"
                />
            </transition>
            <!-- カテゴリー -->
            <transition name="fade" mode="out-in">
                <RegisterCategory
                    v-if="showRegisterCategoryModal" 
                    :isOpen="showRegisterCategoryModal"
                    @close="showRegisterCategoryModal=false"
                    @update-categories="getCategories"
                />
            </transition>
            <transition name="fade" mode="out-in">
                <CategoryEditModal
                    v-if="showCategoryEditModal" 
                    :isOpen="showCategoryEditModal"
                    :category="currentCategory"
                    @close="showCategoryEditModal=false" 
                    @update-category="refreshCategories"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import Loading from "@/components/Loading"
import FlashMessage from '@/components/Admin/FlashMessage'
import RegisterCreator from '@/components/Admin/Modal/RegisterCreator'
import CreatorsTable from '@/components/Admin/DataTable/CreatorsTable'
import TopicsTable from '@/components/Admin/DataTable/TopicsTable'
import ProfileEditModal from '@/components/Admin/Modal/ProfileEditModal'
import MessageModal from '@/components/Admin/Modal/MessageModal'
import RegisterTopic from '@/components/Admin/Modal/RegisterTopic'
import TopicEditModal from '@/components/Admin/Modal/TopicEditModal'
import CategoriesTable from '@/components/Admin/DataTable/CategoriesTable'
import RegisterCategory from '@/components/Admin/Modal/RegisterCategory'
import CategoryEditModal from '@/components/Admin/Modal/CategoryEditModal'

export default {
    name: "creators",
    data() {
        return {
            users: [],
            topics: [],
            categories: [],
            isLoadingOverlay: false,
            isLoading: true,
            openTab: 1,
            // クリエーター
            showRegisterCreatorModal: false,
            showProfileEditModal: false,
            showMessageModal: false,
            currentUser: null,
            // トピック
            showRegisterTopicModal: false,
            showTopicEditModal: false,
            currentTopic: null,
            // カテゴリー
            showRegisterCategoryModal: false,
            showCategoryEditModal: false,
            currentCategory: null,
        };
    },
    watch: {
        showRegisterCreatorModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showProfileEditModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showMessageModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showRegisterTopicModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showTopicEditModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showRegisterCategoryModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showCategoryEditModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
    },
    components: {
        Loading,
        FlashMessage,
        RegisterCreator,
        CreatorsTable,
        ProfileEditModal,
        MessageModal,
        TopicsTable,
        RegisterTopic,
        TopicEditModal,
        CategoriesTable,
        RegisterCategory,
        CategoryEditModal,
    },
    mounted() {
        this.$nextTick(() => {
            this.getUsers();
            this.getTopics();
            this.getCategories();
            document.title = "サイト管理 | iNnos Studio";
        });
    },
    methods: {
        async getUsers() {
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                const response = await axios.get('/api/v1/innos-creators/');
                this.users = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.users = [];
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async getTopics() {
            try {
                const response = await axios.get('/api/v1/innos-creators/topics');
                this.topics = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.topics = [];
            }
        },
        async getCategories() {
            try {
                const response = await axios.get('/api/v1/innos-creators/categories');
                this.categories = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.categories = [];
            }
        },
        async deleteUser(userId) {
            const isConfirmed = confirm('本当に削除しますか？');
            if (!isConfirmed) {
                return;
            }
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                await axios.delete(`/api/v1/innos-creators/delete/${userId}`);
                await this.getUsers();

                this.$store.commit('setFlashMessage', { message: ' クリエーターを削除しました', status: 'success' });
                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async deleteTopic(topicId) {
            const isConfirmed = confirm('本当に削除しますか？');
            if (!isConfirmed) {
                return;
            }
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                await axios.delete(`/api/v1/innos-creators/topics/delete/${topicId}`);
                await this.getTopics();

                this.$store.commit('setFlashMessage', { message: ' トピックを削除しました', status: 'success' });
                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
            } catch (error) {
                const errorMessage = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'カテゴリーの削除に失敗しました'; // デフォルトのエラーメッセージ

                this.$store.commit('setFlashMessage', { message: errorMessage, status: 'error' });
                    setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2500);
                this.closeModal();
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async deleteCategory(categoryId) {
            const isConfirmed = confirm('本当に削除しますか？');
            if (!isConfirmed) {
                return;
            }
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                await axios.delete(`/api/v1/innos-creators/categories/delete/${categoryId}`);
                await this.getCategories();

                this.$store.commit('setFlashMessage', { message: ' カテゴリーを削除しました', status: 'success' });
                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async refreshUsers() {
            await this.getUsers();
        },
        async refreshTopics() {
            await this.getTopics();
        },
        async refreshCategories() {
            await this.getCategories();
        },
        toggleTabs: function(tabNumber){
            this.openTab = tabNumber
        },
        editUser(user) {
            this.currentUser = user
            this.showProfileEditModal = true;
        },
        sendMessage(user) {
            this.currentUser = user
            this.showMessageModal = true;
        },
        editTopic(topic) {
            this.currentTopic = topic
            this.showTopicEditModal = true;
        },
        editCategory(category) {
            this.currentCategory = category
            this.showCategoryEditModal = true;
        },
        goBack() {
            this.$router.push('/');
        },
    },
};
</script>

<style>
.modal-wrapper .fade-enter-active,
.register-creator-modal .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}
.modal-wrapper .fade-enter-from,
.modal-wrapper .fade-leave-to {
    opacity: 0;
}
.modal-wrapper .fade-enter-to,
.modal-wrapper .fade-leave {
    opacity: 1;
}
.table-container {
    overflow-y: auto;
    max-height: calc(100vh - 203px);
}
@media (max-width: 640px) {
    .table-container {
        max-height: calc(100vh - 175px);
    }
}
table th {
    position: sticky;
    top: 0;
    background-color: #353946;
    padding: 16px 16px;
    white-space: nowrap;
    text-align: left;
}
table th:first-child,
table td:first-child {
    /* position: sticky;
    left: 0; */
    padding-left: 24px;
    background-color: #353946;
}
table th:last-child,
table td:last-child {
    padding-right: 24px;
}
table td {
    padding: 10px 16px;
    white-space: nowrap;
    text-align: left;
}
table td:first-child {
    background-color: #22212C;
}
</style>
