<template>
    <div class="bg-white text-color">
        <BlogHeader />

        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="! isLoading" class="max-w-7xl mx-auto py-4 lg:py-8 px-4 md:px-6">
            <div class="grid grid-cols-1 lg:grid-cols-8 gap-4 lg:gap-8 pb-14">

                <div class="lg:col-span-2">
                    <TopicBar 
                        v-bind:topics="topics"
                    />
                </div>

                <div class="lg:col-span-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">

                    <BlogCard  
                        v-for="blog in topic.blogs" 
                        v-bind:key="blog.id"
                        v-bind:blog="blog"
                    />

                </div>
            </div>
        </div>

        <!-- <Footer v-if="! isLoading" /> -->
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import BlogHeader from '@/components/BlogHeader'
import BlogCard from '@/components/Blog/BlogCard'
import TopicBar from '@/components/Blog/TopicBar'
import Footer from '@/components/Footer'

export default {
    name: 'topicDetail',
    data() {
        return {
            topic: {
                blogs: []
            },
            topics: [],
            isLoading: true,
            isLoadingOverlay: false
        }
    },
    components: {
        Loading,
        BlogHeader,
        BlogCard,
        TopicBar,
        Footer
    },
    mounted() {
        this.$nextTick(() => {
            this.getTopic();
            this.getTopics();
        });
    },
    watch: {
        $route(to, from) {
            if (to.name === 'topicDetail') {
                this.getTopic()
            }
        }
    },
    methods: {
        async getTopic() {
            this.isLoading = true;
            this.isLoadingOverlay = true;

            try {
                const topic_slug = this.$route.params.topic_slug;
                const response = await axios.get(`/api/v1/${topic_slug}`);

                this.topic = response.data;
                document.title =  this.topic.name + ' | iNnos Studio';

                await this.$nextTick();
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.blogs = [];
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async getTopics() {
            this.isLoadingOverlay = true;
            try {
                const response = await axios.get('/api/v1/topics');
                this.topics = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.topics = [];
            } finally {
                this.isLoadingOverlay = false;
            }
        },
    },
}
</script>