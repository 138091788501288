<template>
    <div class="bg-white text-color">
        <BlogHeader />

        <FlashMessage />

        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="!isLoading" class="pb-10 max-w-[850px] mx-auto">
            <div>
                <div class="sm:pt-10 pb-4 sm:px-4">
                    <div v-if="blog.get_thumbnail" class="w-full">
                        <img :src="blog.get_thumbnail" class="images" />
                    </div>
                    <div v-else class="images">
                        <img src="@/assets/images/placeholder-image.png" class="w-full h-full object-cover" />
                    </div>
                </div>

                <div class="pt-10 px-4">
                    <div class="space-y-10">
                        <div>
                            <p class="sm:text-sm mb-4 text-negative">
                                {{ moment(blog.created, 'Y年M月D日') }}
                            </p>

                            <h3 class="text-2xl sm:text-3xl font-bold">
                                {{ blog.name }}
                            </h3>
                        </div>

                        <div class='flex justify-between items-center gap-2'>
                            <router-link :to="{ path: '' + blog.host.get_absolute_url }" class="inline-flex items-center gap-3 hover:opacity-80 transition-all">
                                <div>
                                    <div v-if="blog.host.get_avatar" class="w-14 h-14">
                                        <img :src="blog.host.get_avatar" class="rounded-full w-full h-full object-cover" />
                                    </div>
                                    <div v-else class="w-14 h-14">
                                        <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                                    </div>
                                </div>

                                <div class="flex flex-col items-start">
                                    <span class="text-negative text-sm font-bold">
                                        {{ blog.host.job }}
                                    </span>

                                    <p class="font-bold">
                                        {{ blog.host.username }}
                                    </p>
                                </div>
                            </router-link>

                            <button 
                                @click="showModal = true"
                                class="rounded-md whitespace-nowrap px-4 text-center py-3 text-[14px] sm:text-[15px] text-white bg-primary font-bold hover:opacity-80 transition-all"
                            >
                                クリエーターへ相談
                            </button>

                            <transition name="fade" mode="out-in">
                                <ContactModal 
                                    v-if="showModal" 
                                    :isOpen="showModal"
                                    @close="showModal=false" 
                                    :blog="blog"
                                />
                            </transition>
                        </div>

                        <div class="description">
                            <div v-html="blog.description"></div>
                        </div>

                        <div v-if="blog.images.length" class="grid grid-cols-1 gap-4 w-full pt-4">
                            <hr class="mb-8 border-t border-[#E5E7EB]" />
                            <div v-for="image in blog.images" :key="image.id" class="col-span-1">
                                <div>
                                    <img v-lazy="image.get_image" class="images" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Participants 
                        v-if="blog.participants.length"
                        v-bind:blog="blog" 
                    />

                    <RelatedBlogs
                        :topic_slug="$route.params.topic_slug"
                        :blog_uuid="$route.params.blog_uuid"
                    />
                </div>
            </div>
        </div>

        <!-- <Footer v-if="! isLoading" /> -->
        <div class="mt-10"></div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Loading from '@/components/Loading'
import BlogHeader from '@/components/BlogHeader'
import Participants from '@/components/Blog/Participants'
import RelatedBlogs from '@/components/Blog/RelatedBlogs'
import Footer from '@/components/Footer'
import ContactModal from '@/components/Modal/ContactModal'
import FlashMessage from '@/components/FlashMessage.vue'

export default {
    name: 'blogDetail',
    components: {
        Loading,
        BlogHeader,
        Participants,
        RelatedBlogs,
        ContactModal,
        FlashMessage,
        Footer
    },
    data() {
        return {
            blog: {
                host: [],
                participants: [],
                images: []
            },
            isLoading: true,
            isLoadingOverlay: false,
            showModal: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getBlog()
        })
    },
    watch: {
        $route(to, from) {
            this.getBlog(to.params.topic_slug, to.params.blog_uuid);
        },
        showModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },
    methods: {
        async getBlog() {
            this.isLoading = true;
            this.isLoadingOverlay = true;

            try {
                const topic_slug = this.$route.params.topic_slug;
                const blog_uuid = this.$route.params.blog_uuid;

                const response = await axios.get(`/api/v1/${topic_slug}/${blog_uuid}`);

                this.blog = response.data;
                document.title =  this.blog.name + ' | iNnos Studio';
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.blog = null;
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        moment(date, format) {
            return moment(date).format(format)
        },
    },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave {
    opacity: 1;
}
.images {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}
.images img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.description a {
    color: #3b82f6 !important;
    text-decoration: underline !important;
}
.description h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}
.description h1 {
    font-size: 48px;
}
.description h2 {
    font-size: 36px;
}
.description h3 {
    font-size: 24px;
}
.description h4 {
    font-size: 20px;
}
.description h5 {
    font-size: 18px;
}
.description h6 {
    font-size: 16px;
}
.description blockquote {
    margin: 16px 0;
    padding: 16px;
    border-left: 5px solid #252A2E;
    background-color: #f3f4f6;
}
.description img {
    width: 100%;
    max-height: 460px;
}
@media (max-width: 768px) {
    .description img {
        max-height: 400px;
    }
}
@media (max-width: 600px) {
    .description img {
    max-height: 300px;
}
}
</style>