<template>
    <div class="modal-overlay" v-show="isOpen" @click.self="closeModal">
        <div class="modal rounded-md px-4 md:px-10" ref="modal">
            <form @submit.prevent="submitForm" class="modal-content pt-2 pb-6 md:pt-8 md:pb-10" enctype="multipart/form-data">

                <div class="mt-6 mb-2 sm:mb-5">
                    <label>
                        <div v-if="url" class="image-container mb-4">
                            <img :src="url" class="w-full h-full object-cover rounded-md" />
                        </div>
                    </label>

                    <div v-show="!url">
                        <h1 class="sm:text-xl font-bold text-left mb-6">
                            イメージサムネの投稿
                        </h1>
                        <label
                            class="cursor-pointer py-16 px-6 md:px-8 border border-dashed border-gray-300 rounded-md w-full text-center block group"
                        >
                            <div class="flex items-center justify-center gap-1 group-hover:opacity-70 transition-all">
                                <span class="hidden sm:block">
                                    こちらをクリックして画像を追加（ 1ファイル5MB以内 ）
                                </span>
                                <span class="sm:hidden">
                                    こちらをクリックして画像を追加<br>（ 1ファイル5MB以内 ）
                                </span>
                                <i class="fas fa-upload -mt-[2px] text-[15px] hidden sm:inline-block"></i>
                                <input
                                    name="thumbnail"
                                    type="file"
                                    ref="preview"
                                    @change="uploadFile"
                                    class="hidden"
                                />
                            </div>
                            <span class="hidden sm:block mt-2 text-xs font-normal group-hover:opacity-70 transition-all">
                                投稿画像の推奨サイズは『814ピクセル×576ピクセル』アスペクト比は『横：縦＝1：1.414』です。
                            </span>
                            <div class="sm:hidden text-[10px] mt-2 font-normal group-hover:opacity-70 transition-all">
                                投稿画像の推奨サイズは『814ピクセル×576ピクセル』<br>
                                アスペクト比は『横：縦＝1：1.414』です。
                            </div>
                        </label>
                        <div v-if="fileSizeError" class="text-red-500 text-[12px] text-left mt-2">
                            {{ fileSizeError }}
                        </div>

                        <div class="flex justify-end gap-4 mt-8">
                            <button 
                                @click.stop="closeModal"
                                class="w-40 rounded-md px-4 py-[9px] text-[14px] sm:text-[15px] border border-primary font-bold hover:opacity-70 transition-all"
                            >
                                キャンセル
                            </button>
                        </div>
                    </div>
                </div>

                <div v-show="url">
                    <div class="flex flex-col items-start gap-2 mb-5 sm:mb-7">
                        <label for="name" class="font-bold">
                            タイトル
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="w-full">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                v-model="name"
                                class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full"
                            />
                            <div v-if="nameError" class="text-red-500 text-[12px] mt-2 text-left">
                                {{ nameError }}
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-start gap-2 mb-5 sm:mb-7">
                        <label for="topic" class="font-bold">
                            カテゴリー
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <Dropdown
                            :items="topics"
                            :selectedItem="selectedTopic"
                            placeholder="カテゴリーを選択"
                            @item-selected="selectTopic"
                            :image="false"
                        />
                        <div v-if="topicError" class="text-red-500 text-[12px]">
                            {{ topicError }}
                        </div>
                    </div>

                    <div class="flex flex-col items-start gap-[10px] mb-5 sm:mb-7">
                        <label for="images" class="font-bold">
                            画像を追加する
                        </label>
                        <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
                            <div class="col-span-1 relative">
                                <div class="flex items-center justify-center bg-[#EEEEEE] cursor-pointer group image-container">
                                    <input type="file" name="images" id="images" multiple @change="uploadFiles" class="absolute inset-0 opacity-0 cursor-pointer">
                                    <label for="images" class="text-[#A5A5A4] font-bold cursor-pointer group-hover:opacity-70 transition-all">
                                        画像を追加
                                    </label>
                                </div>
                            </div>
                            <div v-for="(image, index) in uploadedImages" :key="image.image" class="col-span-1">
                                <div class="relative">
                                    <img :src="image.src" class="image-container" />
                                    <button @click="removeImage(index)" class="absolute top-1 right-1 bg-white w-7 h-7 text-white rounded-full">
                                        <i class="fas fa-times text-primary text-lg"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-start gap-2 mb-2 sm:mb-4">
                        <label for="user" class="font-bold">
                            参加クリエイター
                        </label>
                        <div class="w-full">
                            <!-- クリエーターを選択 -->
                            <Dropdown
                                :items="users" 
                                :selectedItem="selectedUser" 
                                placeholder="クリエイターを選択"
                                @item-selected="selectUser"
                                :image="true"
                            />
                            <!-- 選択されたクリエイター -->
                            <div class="mt-4 grid md:grid-cols-2 gap-x-4">
                                <div 
                                    v-for="(selectedUser, index) in selectedUsers"
                                    :key="index" 
                                    class="col-span-1 mb-4"
                                >
                                    <div class="flex items-center gap-2 px-3 py-[10px] rounded-md bg-[#EEEEEE]">
                                        <div v-if="selectedUser.get_avatar">
                                            <div  class="w-12 h-12">
                                                <img :src="selectedUser.get_avatar" class="rounded-full w-full h-full object-cover" />
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="w-12 h-12">
                                                <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                                            </div>
                                        </div>
                                        <div class="flex justify-between items-center w-full">
                                            <p class="font-bold text-[15px] flex flex-col items-start">
                                                <span v-if="selectedUser.job" class="text-xs">
                                                    {{ selectedUser.job }}
                                                </span>

                                                <span>
                                                    {{ selectedUser.username }}
                                                </span>
                                            </p>
                                            <button @click="removeUser(index)">
                                                <i class="fas fa-times ml-1 text-xl"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-start gap-2 mb-5 sm:mb-7">
                        <label for="description" class="font-bold">
                            記事の内容
                            <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                        </label>
                        <div class="w-full">
                            <TyniMCEEditor
                                v-model="description"
                                id="description"
                            />
                            <!-- <textarea
                                name="description"
                                id="description"
                                placeholder="例：「この絵画は、私の故郷である田舎の風景からインスピレーションを受けています。私の心の中に生み出す平穏と喜びを表現しています」"
                                v-model="description"
                                class="h-36 w-full border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] placeholder:text-[#A5A5A4]"
                            ></textarea> -->
                            <div v-if="descriptionError" class="text-red-500 text-[12px] mt-[2px] text-left">
                                {{ descriptionError }}
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center gap-4 mt-6 sm:mt-8 mb-5 md:mb-2">
                        <button 
                            @click.stop="closeModal"
                            class="w-40 rounded-md px-4 py-[9px] text-[14px] sm:text-[15px] border border-primary font-bold hover:opacity-70 transition-all"
                        >
                            キャンセル
                        </button>
                        <button 
                            type="submit"
                            :disabled="isSubmitting"
                            class="w-40 rounded-md px-4 py-[10px] text-[14px] sm:text-[15px] text-white bg-primary font-bold hover:bg-hover-primary transition-all"
                        >
                            {{ isSubmitting ? '投稿中...' : '投稿する' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Dropdown from "@/components/Common/Dropdown";
import TyniMCEEditor from "@/components/Common/TinyMCEEditor";

export default {
    props: {
        ['isOpen']: Boolean,
        user: Object,
        topics: Array,
        users: Array,
    },
    components: {
        Dropdown,
        TyniMCEEditor,
    },
    data() {
        return {
            blog: {},
            nameError: '',
            descriptionError: '',
            topicError: '',
            fileSizeError: '',
            url: '',
            name: '',
            description: '',
            topic: '',
            isSubmitting: false,
            uploadedImages: [],
            selectedUsers: [],
            selectedTopic: {},
            selectedUser: {},
        }
    },
    methods: {
        // サムネイル画像処理
        uploadFile() {
            const file = this.$refs.preview.files[0];
            if (file.size > 5 * 1024 * 1024) {
                this.fileSizeError = 'サムネ画像は5MB以下を選択してください';
                return;
            }
            this.url = URL.createObjectURL(file);
        },
        // 作品画像の追加処理
        uploadFiles(event) {
            if (event.target.files && event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];
                    const url = URL.createObjectURL(file);
                    this.uploadedImages.push({ image: file, src: url });
                }
            }
        },
        removeImage(index) {
            this.uploadedImages.splice(index, 1);
        },
        // カテゴリーの選択処理
        selectTopic(topic) {
            this.selectedTopic = topic;
            this.topic = topic.id;
        },
        // 参加クリエーターの追加処理
        addUser(event) {
            const userId = event.target.value;
            const user = this.users.find(user => user.id == userId);
            if(user && !this.selectedUsers.some(u => u.id == userId)){
                this.selectedUsers.push(user);
            }
            event.target.value = "";
        },
        selectUser(user) {
            // 既に選択されているユーザーを追加しないようにチェック
            if (!this.selectedUsers.some(u => u.id === user.id)) {
                this.selectedUsers.push(user);
            }
        },
        removeUser(index) {
            this.selectedUsers.splice(index, 1);
        },
        async submitForm() {
            try {
                if (this.name === "") {
                    this.nameError = "タイトルを記入してください";
                    this.$refs.modal.scrollTop = 400;
                    return;
                } else {
                    this.nameError = "";
                }

                if (this.topic === "") {
                    this.topicError = "カテゴリーを記入してください";
                    this.$refs.modal.scrollTop = 400;
                    return;
                } else {
                    this.topicError = "";
                }

                if (this.description === "") {
                    this.descriptionError = "記事の内容を記入してください";
                    this.$refs.modal.scrollTop = 400;
                    return;
                } else {
                    this.descriptionError = "";
                }

                this.isSubmitting = true;

                // データを格納するための新しいFormDataインスタンスを作成
                let formData = new FormData();

                // 各フィールドをformDataに追加します
                formData.append('host', this.user.id);
                formData.append('thumbnail', this.$refs.preview.files[0]);
                formData.append('name', this.name);
                formData.append('description', this.description);
                formData.append('topic', this.topic);

                // imagesとparticipantsは配列なので、各要素を個別に追加
                for (let i = 0; i < this.uploadedImages.length; i++) {
                    const image = this.uploadedImages[i];
                    formData.append(`images[${i}]`, image.image);
                }
                for (let user of this.selectedUsers) {
                    formData.append('participants', user.id);
                }

                await axios.post('/api/v1/blogs/create/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.$emit('update-blogs');

                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();
                window.scrollTo(0,0);
                this.resetFormFields();
                this.$store.commit('setFlashMessage', { message: '記事を投稿しました', status: 'success' });
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.closeModal();
                this.isSubmitting = false;
                window.scrollTo(0,0);
            }
        },
        resetFormFields() {
            this.name = '';
            this.description = '';
            this.topic = '';
            this.url = '';
            this.uploadedImages = [];
            this.selectedUsers = [];
            this.fileSizeError = '';
        },
        closeModal() {
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 1050px;
    width: 95%;
    max-height: 95%;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    overflow-y: scroll;
}
.modal-content {
    display: flex;
    flex-direction: column;
}
.image-container {
    aspect-ratio: 16 / 9;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
}
.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>