<template>
    <div>
        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="!isLoading">
            <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
                <div class="modal rounded-md px-6 md:px-28">
                    <form @submit.prevent="updateBlog" class="modal-content pt-6 pb-6 md:pt-8 md:pb-10" enctype="multipart/form-data">
                        <div>
                            <div class="mb-5 sm:mb-7">
                                <div 
                                    v-if="blog.get_thumbnail || selectedThumbnail" 
                                    class="w-full sm:rounded-md relative"
                                    @mouseover="showTooltip = true"
                                    @mouseleave="showTooltip = false"
                                >
                                    <img :src="selectedThumbnail || blog.get_thumbnail" class="image-container sm:rounded-md" />
                                    <input type="file" ref="preview" @change="changeThumbnail" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10">
                                    <div class="tooltip hidden sm:block" :class="{ 'tooltip-visible': showTooltip }">画像をクリックしてイメージサムネを変更</div>
                                    <div v-if="fileSizeError" class="text-red-500 text-[12px] text-left mt-2">
                                        {{ fileSizeError }}
                                    </div>
                                </div>
                                <div 
                                    v-else 
                                    class="image-container sm:rounded-md relative"
                                    @mouseover="showTooltip = true"
                                    @mouseleave="showTooltip = false"
                                >
                                    <img src="@/assets/images/placeholder-image.png" class="w-full h-full object-cover sm:rounded-md" />
                                    <input type="file" ref="preview" @change="changeThumbnail" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10">
                                    <div class="tooltip hidden sm:block" :class="{ 'tooltip-visible': showTooltip }">画像をクリックしてイメージサムネを追加</div>
                                    <div v-if="fileSizeError" class="text-red-500 text-[12px] text-left mt-2">
                                        {{ fileSizeError }}
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col items-start gap-2 mb-5 sm:mb-7">
                                <label for="name" class="font-bold">
                                    タイトル
                                    <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                                </label>
                                <div class="w-full">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        v-model="blog.name"
                                        class="border border-gray-200 outline-none rounded-md py-2 px-3 text-[15px] w-full"
                                    />
                                    <div v-if="nameError" class="text-red-500 text-[12px] mt-2 text-left">
                                        {{ nameError }}
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col items-start gap-2 mb-5 sm:mb-7">
                                <label for="topic" class="font-bold">
                                    カテゴリー
                                    <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                                </label>
                                <Dropdown
                                    :items="topics"
                                    :selectedItem="selectedTopic"
                                    placeholder="カテゴリーを選択"
                                    @item-selected="selectTopic"
                                    :image="false"
                                />
                                <div v-if="topicError" class="text-red-500 text-[12px]">
                                    {{ topicError }}
                                </div>
                            </div>

                            <div class="flex flex-col items-start gap-[10px] mb-5 sm:mb-7">
                                <label for="images" class="font-bold">
                                    画像を追加する
                                </label>
                                <div v-if="fileSizeError" class="text-red-500 text-[12px] text-left">
                                    {{ fileSizeError }}
                                </div>
                                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
                                    <div class="col-span-1 relative">
                                        <div class="flex items-center justify-center bg-[#EEEEEE] cursor-pointer group image-container">
                                            <input type="file" name="images" id="images" multiple @change="uploadFiles" class="absolute inset-0 opacity-0 cursor-pointer">
                                            <label for="images" class="text-[#A5A5A4] font-bold cursor-pointer group-hover:opacity-70 transition-all">
                                                画像を追加
                                            </label>
                                        </div>
                                    </div>
                                    <div v-for="(image, index) in uploadedImages" :key="image.image" class="col-span-1">
                                        <div class="relative">
                                            <img :src="image.src" class="image-container" />
                                            <spna @click="handleImageRemove(index)" class="absolute top-1 right-1 bg-white w-7 h-7 text-white rounded-full cursor-pointer flex items-center justify-center">
                                                <i class="fas fa-times text-primary text-lg"></i>
                                            </spna>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col items-start gap-2 mb-2 sm:mb-4">
                                <label for="user" class="font-bold">
                                    参加クリエイター
                                </label>
                                <div class="w-full">
                                    <!-- クリエーターを選択 -->
                                    <Dropdown
                                        :items="users" 
                                        :selectedItem="selectedUser" 
                                        placeholder="クリエイターを選択"
                                        @item-selected="selectUser"
                                        :image="true"
                                    />
                                    <!-- 選択されたクリエイター -->
                                    <div class="mt-3 grid md:grid-cols-2 gap-x-3">
                                        <div 
                                            v-for="(selectedUser, index) in selectedUsers"
                                            :key="index" 
                                            class="col-span-1 mb-3"
                                        >
                                            <div class="flex items-center gap-2 px-3 py-[10px] rounded-md bg-[#EEEEEE]">
                                                <div v-if="selectedUser.get_avatar">
                                                    <div  class="w-12 h-12">
                                                        <img :src="selectedUser.get_avatar" class="rounded-full w-full h-full object-cover" />
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="w-12 h-12">
                                                        <img src="@/assets/images/avatar.png" class="rounded-full w-full h-full object-cover" />
                                                    </div>
                                                </div>
                                                <div class="flex justify-between items-center w-full">
                                                    <p class="font-bold text-[15px] flex flex-col items-start">
                                                        <span v-if="selectedUser.job" class="text-xs">
                                                            {{ selectedUser.job }}
                                                        </span>
                                                        <span>
                                                            {{ selectedUser.username }}
                                                        </span>
                                                    </p>
                                                    <span @click="removeUser(index)">
                                                        <i class="fas fa-times ml-1 text-xl cursor-pointer"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col items-start gap-2 mb-5 sm:mb-7">
                                <label for="description" class="font-bold">
                                    記事の内容
                                    <span class="ml-2 inline-block text-red-500 font-normal text-base">*</span>
                                </label>
                                <div class="w-full">
                                    <TyniMCEEditor
                                        v-model="blog.description"
                                        id="description"
                                    />
                                    <div v-if="descriptionError" class="text-red-500 text-[12px] mt-[2px] text-left">
                                        {{ descriptionError }}
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-center gap-4 mt-6 sm:mt-8 mb-5 md:mb-2">
                                <span
                                    @click.stop="closeModal"
                                    class="w-40 rounded-md px-4 py-[9px] text-[14px] sm:text-[15px] border border-primary font-bold hover:opacity-70 transition-all flex items-center justify-center cursor-pointer"
                                >
                                    キャンセル
                                </span>
                                <button 
                                    type="submit"
                                    :disabled="isSubmitting"
                                    class="w-40 rounded-md px-4 py-[10px] text-[14px] sm:text-[15px] text-white bg-primary font-bold hover:bg-hover-primary transition-all"
                                >
                                    {{ isSubmitting ? '更新中...' : '更新する' }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from "@/components/Loading"
import Dropdown from "@/components/Common/Dropdown"
import TyniMCEEditor from "@/components/Common/TinyMCEEditor"

export default {
    props: {
        ['isOpen']: Boolean,
        blogId: Number,
        topics: Array,
        users: Array,
    },
    components: {
        Loading,
        Dropdown,
        TyniMCEEditor
    },
    data() {
        return {
            blog: {},
            name: "",
            nameError: "",
            description: "",
            descriptionError: "",
            topic: "",
            topicError: "",
            fileSizeError: '',
            uploadedImages: [],
            selectedUsers: [],
            isLoading: true,
            isLoadingOverlay: false,
            isSubmitting: false,
            selectedThumbnail: "",
            showTooltip: false,
            selectedTopic: {},
            selectedUser: {},
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.getBlog();
        });
    },
    methods: {
        // サムネイル画像処理
        changeThumbnail() {
            const file = this.$refs.preview.files[0];
            if (file.size > 5 * 1024 * 1024) {
                this.fileSizeError = 'サムネ画像は5MB以下を選択してください';
                return;
            }
            if (file) {
                this.selectedThumbnail = URL.createObjectURL(file);
            }
        },
        // 作品画像の追加処理
        uploadFiles(event) {
            this.fileSizeError = '';
            if (event.target.files && event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];
                    if (file.size > 5 * 1024 * 1024) { // 5MBの制限
                        this.fileSizeError = 'すべての画像は5MB以下でなければなりません';
                        return;
                    }
                    const url = URL.createObjectURL(file);
                    this.uploadedImages.push({ image: file, src: url, isNew: true });
                }
            }
        },
        handleImageRemove(index) {
            const image = this.uploadedImages[index];
            if (image.isNew) {
                // 新規追加された画像の場合、通常通り削除
                this.uploadedImages.splice(index, 1);
            } else {
                // 既存の画像の場合、deleteBlogImageメソッドを呼び出す
                this.deleteBlogImage(image.id).then(() => {
                    // 削除後の処理
                    this.uploadedImages.splice(index, 1);
                });
            }
        },
        // カテゴリーの選択処理
        selectTopic(topic) {
            this.selectedTopic = topic;
            this.blog.topic = topic.id;
        },
        // 参加クリエーターの追加処理
        addUser(event) {
            const userId = event.target.value;
            const user = this.users.find(user => user.id == userId);
            if(user && !this.selectedUsers.some(u => u.id == userId)){
                this.selectedUsers.push(user);
            }
            event.target.value = "";
        },
        selectUser(user) {
            // 既に選択されているユーザーを追加しないようにチェック
            if (!this.selectedUsers.some(u => u.id === user.id)) {
                this.selectedUsers.push(user);
            }
        },
        removeUser(index) {
            this.selectedUsers.splice(index, 1);
        },
        async getBlog() {
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                const response = await axios.get(`/api/v1/blogs/${this.blogId}`);
                this.blog = response.data;
                const existingTopic = this.topics.find(topic => topic.id === this.blog.topic);
                if (existingTopic) {
                    this.selectedTopic = existingTopic;
                }
                this.selectedUsers = response.data.participants || [];
                this.uploadedImages = response.data.images.map(image => ({
                    // 既存の画像データの形式に合わせる
                    id: image.id, // 画像のID
                    image: image.image, // サーバーからの画像ファイル名またはパス
                    src: image.get_image, // サーバーからの完全な画像URL
                    isNew: false // 既存の画像であることを示すフラグ
                }));
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async updateBlog() {
            try {
                if (this.blog.name === "") {
                    this.nameError = "タイトルを記入してください";
                    this.$refs.modal.scrollTop = 400;
                    return;
                } else {
                    this.nameError = "";
                }

                if (this.blog.topic === "") {
                    this.topicError = "カテゴリーを記入してください";
                    this.$refs.modal.scrollTop = 400;
                    return;
                } else {
                    this.topicError = "";
                }

                if (this.blog.description === "") {
                    this.descriptionError = "記事の内容を記入してください";
                    this.$refs.modal.scrollTop = 400;
                    return;
                } else {
                    this.descriptionError = "";
                }

                this.isSubmitting = true;

                // データを格納するための新しいFormDataインスタンスを作成
                let formData = new FormData();

                formData.append('host', this.blog.host.id);
                formData.append('name', this.blog.name);
                formData.append('description', this.blog.description);
                formData.append('topic', this.blog.topic);

                if (this.$refs.preview && this.$refs.preview.files.length > 0) {
                    const file = this.$refs.preview.files[0];
                    formData.append('thumbnail', file);
                }

                for (let i = 0; i < this.uploadedImages.length; i++) {
                    const image = this.uploadedImages[i];
                    // image.imageがFileオブジェクトの場合のみ、FormDataに追加
                    if (image.image instanceof File) {
                        formData.append(`images[${i}]`, image.image);
                    } else {
                        // 既存の画像の場合、その識別子を別のフィールドで送信
                        // ここでは、既存の画像のパスをそのまま識別子として使用
                        formData.append('existingImages', image.image);
                    }
                }

                this.selectedUsers.forEach(user => {
                    formData.append('participants', user.id);
                });

                await axios.put(`/api/v1/blogs/update/${this.blogId}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.$emit('update-blogs');

                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
                this.closeModal();
                this.$store.commit('setFlashMessage', { message: '記事を更新しました', status: 'success' });
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            }
        },
        async deleteBlogImage(imageId) {
            try {
                await axios.delete(`/api/v1/images/delete/${imageId}`);
            } catch (error) {
                console.error(error);
            }
        },
        closeModal() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    max-width: 1050px;
    width: 95%;
    max-height: 95%;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    overflow-y: scroll;
}
.modal-content {
    display: flex;
    flex-direction: column;
}
.image-container {
    aspect-ratio: 16 / 9;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
}
.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
}
.tooltip-visible {
    visibility: visible;
}
</style>