<template>
    <div>
        <table class="w-full">
            <thead>
                <tr class="bg-[#353946]">
                    <th>No.</th>
                    <th>カテゴリー名</th>
                    <th>スラッグ（ パーマリンク ）</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(category, index) in categories" :key="category.id" class="border-b border-gray-200">
                    <td>{{ categories.length - index }}</td>
                    <td>{{ category.name }}</td>
                    <td>/category/{{ category.slug }}</td>
                    <td>
                        <div class="flex gap-[10px]">
                            <button 
                                class="text-sm bg-green-600 hover:bg-green-700 rounded-full w-8 h-8 transition-all hover:text-gray-200"
                                @click="editCategory(category)"
                            >
                                <i class="fas fa-edit"></i>
                            </button>
                            <button 
                                class="text-sm bg-red-600 hover:bg-red-700 rounded-full w-8 h-8 transition-all hover:text-gray-200"
                                @click="deleteCategory(category.id)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "categories-table",
    props: {
        categories: {
            type: Array,
            required: true
        },
    },
    methods: {
        editCategory(category) {
            this.$emit('edit-category', category);
        },
        deleteCategory(categoryId) {
            this.$emit('delete-category', categoryId);
        },
    }
}
</script>
