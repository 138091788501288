<template>
  <Editor
    :id="id"
    v-model="editorHTML"
    style="z-index: 1000"
    :init="initTinyMCE"
  />
</template>

<script>
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/quickbars/plugin';
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    Editor
  },
  props: {
    modelValue: { type: String, default: '' },
    id: { type: String, default: 'editor' },
    menubar: { type: Boolean, default: false },
    pluginList: {
      type: String,
      default:
        'anchor lists advlist autoresize autolink charmap directionality image link lists media table searchreplace quickbars',
    },
    language: { type: String, default: 'ja' },
    editorMaxHeight: { type: Number, default: 400 },
  },
  data() {
    return {
      editorHTML: this.modelValue,
      initTinyMCE: {
        menubar: this.menubar,
        plugins: this.pluginList,
        language_url: '/tinymce/langs/ja.js',
        language: this.language,
        max_height: this.editorMaxHeight,
        images_file_types: 'jpeg,jpg,png,gif',
        file_picker_types: 'image',
        quickbars_insert_toolbar: false,
        branding: false,
        skin: false,
        content_css: false,
      },
    };
  },
  watch: {
    modelValue(newValue) {
      this.editorHTML = newValue;
    },
    editorHTML(newValue) {
      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>

<style lang="scss">
.tox-tinymce-aux {
  z-index: 99999999999 !important;
}
</style>
