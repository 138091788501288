<template>
    <nav class="border-b bg-white"> 
        <div class="max-w-7xl mx-auto flex justify-between items-center h-[78px] px-4 md:px-6">

            <router-link to="/" class='inline-block font-bold flex items-center cursor-pointer w-[110px] md:w-[130px] pt-1'>
                <img src="../assets/images/innos-logo.svg" alt="logo" class='w-full h-full object-cover' />
            </router-link>

            <div class='flex items-center text-base justify-center gap-6 sm:gap-8'>
                <div 
                    v-if="!$store.state.isAuthenticated"
                    class="flex items-center gap-2 sm:gap-4"
                >
                    <router-link 
                        to="/signup" active-class="active-login" 
                        class="rounded-md px-4 py-[7px] text-[14px] sm:text-[15px] border border-primary font-bold hover:opacity-70 transition-all"
                    >
                        新規登録
                    </router-link>
                    <router-link 
                        to="/login" active-class="active-login" 
                        class="rounded-md px-4 py-2 text-[14px] sm:text-[15px] text-white bg-primary font-bold hover:bg-hover-primary transition-all"
                    >
                        ログイン
                    </router-link>
                </div>

                <div v-else>
                    <div v-if="! isLoading" class="w-full">
                        <div class="relative">
                            <button 
                                type="button" @click="toggleDropdown()"
                                class="flex items-center gap-3 font-bold transition-all py-2"
                            >
                                <div class="flex flex-col gap-[2px] text-sm w-32 md:w-full">
                                    <span class="text-negative text-xs">{{ user.job || 'プロフィールが未設定です' }}</span>
                                    <span class="line-clamp-1">
                                        {{ user.username }}
                                    </span>
                                </div>

                                <div>
                                    <div v-if="user.get_avatar" class="w-14 h-14">
                                        <img :src="user.get_avatar" class="rounded-full w-full h-full object-cover" />
                                    </div>
                                    <div v-else class="w-14 h-14">
                                        <img src="../assets/images/avatar.png" class="rounded-full w-full h-full object-cover group-hover:opacity-80 transition-all" />
                                    </div>
                                </div>
                            </button>

                            <div 
                                v-if="dropdownPopoverShow"
                                class="w-[180px] absolute top-18 right-0 bg-white text-base z-50 border border-gray-100 p-3 rounded shadow-lg space-y-2" 
                            >
                                <div v-if="user.is_admin === true">
                                    <router-link 
                                        :to="{ path: '/admin/creators' }" active-class="active" 
                                        class="text-base py-2 px-4 font-bold block w-full whitespace-nowrap rounded hover:bg-gray-100 transition-all"
                                    >
                                        <i class="fas fa-users-cog mr-1"></i>
                                        会員管理
                                    </router-link>
                                </div>

                                <router-link 
                                    :to="{ path: '/mypage/' + user.uuid }" active-class="active" 
                                    class="text-base py-2 px-4 font-bold block w-full whitespace-nowrap rounded hover:bg-gray-100 transition-all"
                                >
                                    <i class="fas fa-user-circle mr-2"></i>
                                    マイページ
                                </router-link>

                                <button @click="logout()" class="text-base text-left py-2 px-4 font-bold block w-full whitespace-nowrap rounded hover:bg-gray-100 transition-all">
                                    <i class="fas fa-sign-out-alt mr-2"></i>
                                    ログアウト
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            user: {},
            dropdownPopoverShow: false,
            isLoading: true,
        }
    },
    mounted() {
        this.getUser();
        document.title = 'Create Blog | iNnos Studio';
        document.addEventListener('click', this.handleGlobalClick);
    },
    unmounted() {
        document.removeEventListener('click', this.handleGlobalClick);
    },
    methods: {
        async getUser() {
            try {
                const response = await axios.get('/api/v1/user-role');
                this.user = response.data;
                this.isLoading = false;
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.isLoading = false;
                this.user = null;
            }
        },
        toggleDropdown() {
            this.dropdownPopoverShow = !this.dropdownPopoverShow;
        },
        handleGlobalClick(e) {
            if (!this.$el.contains(e.target)) {
                this.dropdownPopoverShow = false;
            }
        },
        logout() {
            try {
                axios.defaults.headers.common["Authorization"] = "";
                localStorage.removeItem("token");
                localStorage.removeItem("username");
                localStorage.removeItem("userid");
                this.$store.commit('removeToken');
                this.$router.push('/');
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            }
        },
    }
}
</script>

<style scoped>
    .active-login {
        pointer-events: none;
    }
    .active {
        pointer-events: none;
        background-color: #F3F4F6;
    }
</style>
