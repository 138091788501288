<template>
    <div class="bg-white text-color">
        <BlogHeader :key="updateKey" />

        <FlashMessage />

        <Loading v-if="isLoadingOverlay" v-bind:isLoading="isLoading" />

        <div v-if="!isLoading" class="max-w-[850px] mx-auto py-6 sm:py-8 px-4 md:px-6">
            <div class="w-full">
                <div class="text-right">
                    <button
                        @click="showProfileEditModal = true"
                        class="text-sm bg-primary text-white font-bold rounded-md px-4 py-2 hover:opacity-80 transition-all"
                    >
                        <span class="hidden sm:block">
                            プロフィールを編集
                        </span>
                        <span class="sm:hidden">
                            <i class="fas fa-pencil-alt sm:text-lg"></i>
                        </span>
                    </button>
                </div>
                <div class="max-w-[350px] mx-auto">
                    <div 
                        class="mb-6 cursor-pointer hover:opacity-90 transition-all"
                        @click="showProfileEditModal = true"
                    >
                        <div v-if="user.get_avatar" class="w-28 h-28 sm:w-32 sm:h-32 mx-auto rounded-full">
                            <img
                                :src="user.get_avatar"
                                class="w-full h-full object-cover rounded-full"
                            />
                        </div>
                        <div v-else class="w-28 h-28 sm:w-32 sm:h-32 mx-auto rounded-full">
                            <img
                                src="@/assets/images/avatar.png"
                                class="w-full h-full object-cover rounded-full"
                            />
                        </div>
                    </div>

                    <div class="text-center font-bold sm:text-lg mb-6">
                        <p class="text-negative text-xs sm:text-sm mb-1">
                            {{ user.job }}
                        </p>
                        {{ user.username }}
                    </div>

                    <div class="sm:leading-7 leading-6 text-sm sm:text-base">
                        {{ user.bio }}
                    </div>

                    <div class="flex items-center justify-center gap-8 sm:gap-10 mt-7">
                        <a v-if="user.twitter" :href="user.twitter" target="_blank" rel="noopener noreferrer" class="inline-block hover:opacity-80 transition-all">
                            <i class="fab fa-twitter text-[#1C9AEE] text-2xl"></i>
                        </a>
                        <a v-if="user.instagram" :href="user.instagram" target="_blank" rel="noopener noreferrer" class="inline-block hover:opacity-80 transition-all">
                            <i class="fab fa-instagram text-[#E1306C] text-2xl"></i>
                        </a>
                        <a v-if="user.youtube" :href="user.youtube" target="_blank" rel="noopener noreferrer" class="inline-block hover:opacity-80 transition-all">
                            <i class="fab fa-youtube text-[#FF0000] text-2xl"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="user.blogs" class="grid sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-3 w-full mt-10">
                <div v-for="blog in user.blogs" :key="blog.id" class="col-span-1">
                    <router-link v-bind:to="blog.get_absolute_url" class="hover:opacity-90 transition-all relative">
                        <div>
                            <img v-lazy="blog.get_thumbnail" class="thumbnail rounded-md" />
                            <div class="absolute top-0 right-0 p-1">
                                <button @click.stop.prevent="toggleSettings(blog.id)" class="flex justify-center items-center text-white w-[30px] h-[30px] rounded-full p-1 bg-gray-900 bg-opacity-70 hover:bg-gray-800 transition-all">
                                    <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <div v-if="showSettings[blog.id]" class="bg-white shadow-lg rounded p-2 absolute right-1 mt-1 border border-gray-100">
                                    <ul>
                                        <li>
                                            <button 
                                                @click.stop.prevent="editBlog(blog.id)" 
                                                class="block w-full text-left px-4 py-2 hover:bg-gray-100 whitespace-nowrap rounded text-sm font-bold"
                                            >
                                                <i class="fas fa-edit mr-[6px]"></i>
                                                編集
                                            </button>
                                        </li>
                                        <li>
                                            <button 
                                                @click.stop.prevent="deleteBlog(blog.id)" 
                                                class="block w-full text-left px-4 py-2 hover:bg-gray-100 whitespace-nowrap rounded text-sm font-bold"
                                            >
                                                <i class="fas fa-trash-alt mr-2"></i>
                                                削除
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="text-center mt-12">
                <button 
                    @click="showBlogModal = true"
                    class="rounded-md w-40 tracking-widest text-center py-3 text-white bg-primary font-bold hover:opacity-80 transition-all"
                >
                    <i class="fas fa-image"></i>
                    投稿
                </button>
            </div>

            <transition name="fade" mode="out-in">
                <BlogModal 
                    v-if="showBlogModal" 
                    :isOpen="showBlogModal"
                    @close="showBlogModal=false" 
                    :user="user"
                    :topics="topics"
                    :users="users"
                    @update-blogs="refreshBlogs"
                />
            </transition>

            <transition name="fade" mode="out-in">
                <BlogEditModal 
                    v-if="showEditBlogModal" 
                    :isOpen="showEditBlogModal"
                    :blog-id="editBlogId" 
                    @close="showEditBlogModal=false"  
                    :topics="topics"
                    :users="users"
                    @update-blogs="refreshBlogs"
                />
            </transition>

            <transition name="fade" mode="out-in">
                <ProfileEditModal
                    v-if="showProfileEditModal" 
                    :isOpen="showProfileEditModal"
                    @close="showProfileEditModal=false" 
                    :user="user"
                    @update-user="refreshBlogs"
                />
            </transition>
        </div>

        <!-- <Footer v-if="! isLoading" /> -->
        <div class="mt-10"></div>
    </div>
</template>

<script>
import axios from "axios"
import Loading from "@/components/Loading"
import BlogHeader from "@/components/BlogHeader"
import Footer from "@/components/Footer"
import FlashMessage from '@/components/FlashMessage.vue'
import BlogModal from '@/components/Modal/BlogModal'
import BlogEditModal from '@/components/Modal/BlogEditModal'
import ProfileEditModal from '@/components/Modal/ProfileEditModal'

export default {
    name: "maypage",
    data() {
        return {
            user: {
                blogs: []
            },
            topics: [],
            users: [],
            isLoadingOverlay: false,
            isLoading: true,
            showSettings: {},
            editBlogId: null,
            showBlogModal: false,
            showEditBlogModal: false,
            showProfileEditModal: false,
            updateKey: 0,
        };
    },
    watch: {
        showBlogModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showEditBlogModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        showProfileEditModal(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
    },
    components: {
        Loading,
        BlogHeader,
        Footer,
        FlashMessage,
        BlogModal,
        BlogEditModal,
        ProfileEditModal,
    },
    mounted() {
        this.$nextTick(() => {
            this.getUser()
            this.getTopics()
            this.getUsers()
            document.title = "MyPage | iNnos Studio"
        });
        document.addEventListener('click', this.handleGlobalClick);
    },
    unmounted() {
        document.removeEventListener('click', this.handleGlobalClick);
    },
    methods: {
        async getUser() {
            this.isLoading = true;
            this.isLoadingOverlay = true;

            try {
                const user_uuid = this.$route.params.user_uuid;
                const response = await axios.get(`/api/v1/users/${user_uuid}`);

                this.user = response.data;

                await this.$nextTick();
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.user = null;
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        async getTopics() {
            try {
                const response = await axios.get('/api/v1/topics/');
                this.topics = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.topics = [];
            }
        },
        async getUsers() {
            try {
                const response = await axios.get('/api/v1/creators/');
                this.users = response.data;
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
                this.users = [];
            }
        },
        async refreshBlogs() {
            await this.getUser();
            this.updateKey++;
        },
        async deleteBlog(blogId) {
            const isConfirmed = confirm('本当に削除しますか？');
            if (!isConfirmed) {
                this.showSettings[blogId] = false;
                return;
            }
            this.isLoading = true;
            this.isLoadingOverlay = true;
            try {
                await axios.delete(`/api/v1/blogs/delete/${blogId}`);
                await this.getUser();

                this.$store.commit('setFlashMessage', { message: '作品を削除しました', status: 'success' });
                setTimeout(() => {
                    this.$store.commit('clearFlashMessage');
                }, 2000);
            } catch (error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            } finally {
                this.isLoading = false;
                this.isLoadingOverlay = false;
            }
        },
        toggleSettings(blogId) {
            const isCurrentlyOpen = this.showSettings[blogId];

            Object.keys(this.showSettings).forEach(key => {
                this.showSettings[key] = false;
            });

            if (!isCurrentlyOpen) {
                this.showSettings[blogId] = true;
            }
        },
        handleGlobalClick(e) {
            // 全てのメニューを閉じる
            Object.keys(this.showSettings).forEach(key => {
                this.showSettings[key] = false;
            });
        },
        editBlog(blogId) {
            this.editBlogId = blogId;
            this.showEditBlogModal = true;
            this.showSettings[blogId] = false;
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave {
    opacity: 1;
}
.thumbnail {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}
.thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>